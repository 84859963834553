import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserIcon } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import s from "./Tasks.module.css";
import tick from "../../../../assets/images/tick.svg";
import Delete from "../../../../assets/images/delete.svg";
import {
  addPatientFullName,
  addPatientId,
} from "../../../../redux/patient/PatientSlice";

import Pagination from "../../../../components/Pagination";
import { DisplayTaskModal } from "../../../../components/Modal/DisplayTaskModal/DisplayTaskModal";
import { AddTaskModal } from "../../../../components/AddTaskModal/AddTaskModal";
import { useTasks } from "../../../../hooks/useTasks";
import { useDeleteTask } from "../../../../hooks/useDeleteTask";
import { DeleteModal } from "../../../../components/Modal/DeleteModal";
import moment from "moment";
import { IconButton } from "@mui/material";

export const Tasks = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.auth.user?.type || "");

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const { data: tasks, isLoading } = useTasks();
  const { mutate: deleteTask } = useDeleteTask();

  const itemsPerPage = 25;
  const totalPages = Math.ceil((tasks?.length || 0) / itemsPerPage);
  const currentItems =
    tasks?.slice(
      currentPage * itemsPerPage - itemsPerPage,
      currentPage * itemsPerPage
    ) || [];

  const handleDeleteTask = (id) => {
    setSelectedTaskId(id);
    setShowModal(false);
  };

  const goToPatientDetails = (id, patientFullName) => {
    dispatch(addPatientId(id));
    localStorage.setItem("kinesin-patientId", id);
    dispatch(addPatientFullName(patientFullName));
    if (userType === "GP") {
      navigate("/patient-detail-gp");
    } else {
      navigate("/patient-detail");
    }
  };

  const handleDisplayTask = async (id) => {
    const selectedTask = tasks.find((item) => item.id === id);

    if (selectedTask) {
      setSelectedTaskId(selectedTask.id);
      setShowModal(true);
    }
  };

  return (
    <>
      <div className={s.container}>
        <div className={s.headerContainer}>
          <div className={s.headerTitleContainer}>
            <h2 className={s.headerTitle}>Tasks</h2>
            {tasks && (
              <div className={s.headerTasksQuantity}>{tasks.length}</div>
            )}
          </div>
          <AddTaskModal buttonText="Add" />
        </div>

        <div className={s.contentContainer}>
          {!isLoading && currentItems?.length === 0 && (
            <p className="no-record-text">No Records Found</p>
          )}
          <ul>
            {currentItems?.map((item, i) => {
              return (
                <li
                  key={item.id}
                  className={`${s.listItem} ${
                    i !== currentItems.length - 1 ? s.borderBottom : ""
                  }`}
                >
                  <div className={s.taskNumber}>{i + 1}</div>
                  <div className={s.taskDetails}>
                    <p
                      className={s.taskTitle}
                      onClick={() => handleDisplayTask(item.id)}
                    >
                      {item.title}
                    </p>
                    <div className={s.taskInfoContainer}>
                      <span className={s.taskDate}>
                        {moment(item.dueDate).format("DD.MM.YYYY")}
                      </span>
                      <div className={s.taskPatientContainer}>
                        <UserIcon
                          className="userIcon"
                          color="#26252C"
                          width={12}
                          height={12}
                        />
                        <span className={s.taskPatientName}>
                          {item.patient?.firstName} {item.patient?.surname}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className={s.buttonsContainer}>
                    <IconButton
                      className={s.actionButton}
                      aria-label="View user"
                      onClick={() => {
                        goToPatientDetails(
                          item.patient.id,
                          item.patient.firstName + " " + item.patient.surname
                        );
                      }}
                    >
                      <UserIcon
                        className="userIcon"
                        color="#8d8da3"
                        width={20}
                        height={20}
                      />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDisplayTask(item.id)}
                      className={`${s.actionButton} ${
                        item.status === "Complete" ? "green-tick" : ""
                      }`}
                    >
                      <img src={tick} alt="" />
                    </IconButton>
                    <IconButton
                      data-bs-toggle="modal"
                      data-bs-target="#myModal"
                      onClick={() => handleDeleteTask(item.id)}
                      className={s.actionButton}
                    >
                      <img src={Delete} alt="" />
                    </IconButton>
                  </div>
                </li>
              );
            })}
          </ul>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>

      <DeleteModal
        deleteService={() => {
          deleteTask(selectedTaskId);
        }}
        context="task"
        itemName="task"
      />
      <DisplayTaskModal
        closeModal={() => {
          setShowModal(false);
        }}
        task={tasks?.find((item) => item.id === selectedTaskId)}
        isOpen={showModal}
      />
    </>
  );
};
