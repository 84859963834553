import style from "./ConsultationBox.module.css";
import dayjs from "dayjs";
import { useState } from "react";
import { toast } from "react-hot-toast";

import Modal from "../../../../../../components/helpers/Modal/Modal.jsx";
import { StartConsultationForm } from "../StartConsultationForm/StartConsultationForm";
import CustomButton from "../../../../../../components/helpers/CustomButton/CustomButton";
import { usePracticeServices } from "../../../../../../hooks/usePracticeServices";
import { useConsultationNote } from "../../../../../../hooks/useConsultationNote.js";
import { useFinishConsultation } from "../../../../../../hooks/useFinishConsultation";
import { useAddConsultationService } from "../../../../../../hooks/useAddConsultationService";
import { SaveTemplateModal } from "./components/SaveTemplateModal";
import { useSaveTemplate } from "../../../../../../hooks/useSaveTemplate.js";
import { PaymentSection } from "./components/Paymentsection.jsx";

export const ConsultationBox = ({
  patient,
  staffName,
  consultation,
  saveAsTemplate,
  setSaveAsTemplate,
  onFinish,
}) => {
  const isPaid = consultation?.moneyOwed === false;
  const hasAppointment = patient && patient?.nextApppointment;
  const [service, setService] = useState("Select Service");
  const [isModalOpen, setIsModalOpen] = useState({ open: false, id: null });
  const [showSaveTemplate, setShowSaveTemplate] = useState(false);

  const hasActiveConsultation = !!consultation;

  const { data: consultationNote } = useConsultationNote(consultation?.id);

  const { data: services, isLoading: isServicesLoading } =
    usePracticeServices();

  const finishConsultation = useFinishConsultation(
    consultation?.id,
    patient?.id,
    consultation
  );
  const addConsultationService = useAddConsultationService(consultation?.id);
  const saveTemplate = useSaveTemplate();

  const handleFinishConsultation = () => {
    if (!consultationNote || consultationNote?.length === 0) {
      toast.error("Please add consultation note before finishing");
      return;
    }

    if (saveAsTemplate) {
      setShowSaveTemplate(true);
    } else {
      onFinish();
      finishConsultation.mutate();
    }
  };

  const handleSaveTemplate = async (templateName) => {
    try {
      await saveTemplate.mutateAsync({
        name: templateName,
        note: consultationNote?.at(0)?.note,
      });

      finishConsultation.mutate();
      onFinish();
      setSaveAsTemplate(false);
      setShowSaveTemplate(false);
    } catch (error) {
      console.error("Failed to save template:", error);
    }
  };

  return (
    <>
      <div className={style.consultationBoxWrapper}>
        <section className={style.consultationBox}>
          <div className={style.consultationBoxTop}>
            <div className={style.consultationBoxTopItem}>
              <p>
                {hasActiveConsultation
                  ? "Current Consultation"
                  : "Planned consultation"}
              </p>
              <p>
                {hasActiveConsultation
                  ? dayjs(consultation?.created, "DD-MM-YYYY HH:mm").format(
                      "DD MMM YYYY HH:mm"
                    )
                  : hasAppointment
                  ? patient.nextApppointment
                  : "None scheduled"}
              </p>
            </div>
            <div>
              {hasActiveConsultation ? (
                <CustomButton
                  buttonHandle={() => handleFinishConsultation()}
                  title="Finish consultation"
                  buttonClassName={style.button}
                  icon={false}
                />
              ) : (
                <CustomButton
                  buttonClassName={style.button}
                  icon={false}
                  title="Start consultation"
                  buttonHandle={() => setIsModalOpen({ open: true })}
                />
              )}
            </div>
          </div>
          {hasActiveConsultation && (
            <div className={style.addServiceWrapper}>
              <select
                as="select"
                className="form-select form-control custom-select"
                value={service}
                onChange={(e) => setService(e.target.value)}
                disabled={isPaid}
              >
                <option value="Select Service">Select Service</option>
                {isServicesLoading ? (
                  <option>Loading services...</option>
                ) : (
                  (services ?? []).map((item, index) => (
                    <option value={item.id || index} key={item.id}>
                      {item.name}
                    </option>
                  ))
                )}
              </select>
              <button
                className={style.addServiceButton}
                onClick={() => {
                  if (service === "Select Service") {
                    toast.error("Please select a service");
                    return;
                  }
                  addConsultationService.mutate(service);
                }}
                disabled={isPaid}
              >
                Add additional service
              </button>
            </div>
          )}
        </section>
        <PaymentSection consultation={consultation} />
      </div>
      {isModalOpen.open && (
        <Modal
          title="Start Consultation"
          position="center"
          onClose={() => setIsModalOpen({ open: false })}
          hideCancel={true}
          width="570px"
        >
          <StartConsultationForm
            closeModal={() => setIsModalOpen({ open: false })}
            staffName={staffName}
            hasAppointment={hasAppointment}
            appointmentId={patient?.nextAppointmentId}
          />
        </Modal>
      )}
      <SaveTemplateModal
        isOpen={showSaveTemplate}
        onClose={() => setShowSaveTemplate(false)}
        onSave={handleSaveTemplate}
        isSaving={saveTemplate.isPending}
      />
    </>
  );
};
