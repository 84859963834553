import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useSentPaginatedTasks = ({ search, page }) => {
    const handleNetworkError = useHandleNetworkError((error) =>
        toast.error(`Error fetching sent tasks: ${error.message}`)
    );

    const tenantVal =
        useSelector((state) => state.auth.user?.tenant) ||
        localStorage.getItem("kinesin-tenant");

    return useQuery({
        queryKey: ["sentPaginatedTasks", search, page, tenantVal],
        queryFn: async () => {
            const axiosInstance = await createAxiosInstance();
            const response = await axiosInstance.get( `${tenantVal}/task/getmytaskcreatedby/page?page=${page}`
            );

            return response.data;
        },
        onError: handleNetworkError,
        staleTime: 1000 * 60 * 5,
        refetchOnWindowFocus: true,
    });
};
