import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import consult from "../../../../../assets/images/navbar/consult.svg";
import consultations from "../../../../../assets/images/navbar/consultations.svg";
import details from "../../../../../assets/images/navbar/details.svg";
import medications from "../../../../../assets/images/navbar/medications.svg";
import documents from "../../../../../assets/images/navbar/documents.svg";
import result from "../../../../../assets/images/navbar/results.svg";
import protocols from "../../../../../assets/images/navbar/protocols.svg";
import immunizations from "../../../../../assets/images/navbar/immunizations.svg";
import family from "../../../../../assets/images/navbar/family.svg";
import pregnancy from "../../../../../assets/images/navbar/pregnancy.svg";
import communications from "../../../../../assets/images/navbar/communications.svg";
import payments from "../../../../../assets/images/navbar/payments.svg";
import cdm from "../../../../../assets/images/navbar/cdm.svg";

import styles from "./NavigationBar.module.css";

const menuItems = [
  { src: consult, label: "Consult", url: "/patient-consult", className: "" },
  {
    src: consultations,
    label: "Consultations",
    url: "/patient-consultations",
    className: "",
  },
  {
    src: medications,
    label: "Medications",
    url: "/patient-medication",
    className: "",
  },
  {
    src: documents,
    label: "Documents",
    url: "/patient-documents-gp",
    className: "",
  },
  { src: result, label: "Results", url: "/patient-results", className: "" },
  {
    src: protocols,
    label: "Protocols",
    url: "/patient-protocols",
    className: "",
  },
  {
    src: immunizations,
    label: "Immunisations",
    url: "/patient-immunizations",
    className: "",
  },
  {
    src: details,
    label: "Details",
    url: "/patient-detail-gp",
    className: "",
  },
  {
    src: cdm,
    label: "Chronic Disease",
    url: "/cdm",
    className: "",
  },
  {
    src: pregnancy,
    label: "Pregnancy",
    url: "/patient-pregnancy",
    className: "",
  },
  { src: family, label: "Family", url: "/patient-family", className: "" },
  {
    src: communications,
    label: "Communications",
    url: "/patient-communication",
    className: "",
  },
  {
    src: payments,
    label: "Payments",
    url: "/patient-payments",
    className: "",
  },
];

const NavigationBar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [hiddenItems, setHiddenItems] = useState([]);
  const [visibleItems, setVisibleItems] = useState([]);

  useEffect(() => {
    const updateHiddenItems = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 1920 && screenWidth > 1600) {
        setHiddenItems(menuItems.slice(-3));
        setVisibleItems(menuItems.slice(0, -3));
      } else if (screenWidth <= 1600 && screenWidth > 1400) {
        setHiddenItems(menuItems.slice(-4));
        setVisibleItems(menuItems.slice(0, -4));
      } else if (screenWidth <= 1400 && screenWidth > 1200) {
        setHiddenItems(menuItems.slice(-5));
        setVisibleItems(menuItems.slice(0, -5));
      } else if (screenWidth <= 1200 && screenWidth > 700) {
        setHiddenItems(menuItems.slice(-7));
        setVisibleItems(menuItems.slice(0, -7));
      } else if (screenWidth <= 700 && screenWidth > 360) {
        setHiddenItems(menuItems.slice(-9));
        setVisibleItems(menuItems.slice(0, -9));
      } else {
        setHiddenItems([]);
        setVisibleItems(menuItems);
      }
    };

    updateHiddenItems();
    window.addEventListener("resize", updateHiddenItems);

    return () => {
      window.removeEventListener("resize", updateHiddenItems);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const getMenuStyle = (url) => {
    if (window.location.pathname === url) return styles.navLinkChecked;

    return styles.navLink;
  };

  return (
    <div className={styles.navbar}>
      <div className={styles.navLinks}>
        {visibleItems.map((item, index) => (
          <Link to={item.url} key={index} className={getMenuStyle(item.url)}>
            <img className={styles.navIcon} src={item.src} alt={item.label} />
            <span>{item.label}</span>
          </Link>
        ))}
        {hiddenItems.length > 0 && (
          <div className={styles.moreButtonWrapper}>
            <button className={styles.moreButton} onClick={toggleDropdown}>
              ⋮
            </button>
            {isDropdownOpen && (
              <div className={styles.dropdownMenu}>
                {hiddenItems.map((item, index) => (
                  <a
                    key={index}
                    href={item.url}
                    className={styles.dropdownItem}
                  >
                    <img
                      className={styles.navIcon}
                      src={item.src}
                      alt={item.label}
                    />
                    <span>{item.label}</span>
                  </a>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NavigationBar;
