import React, { useEffect, useRef, useState } from "react";
import "../../../assets/css/allied_settings.css";
import camera from "../../../assets/images/camera.svg";
import Tab from "../../../components/SettingsTab/Tab";
import { useFormik } from "formik";
import * as yup from "yup";
import { createAxiosInstance } from "../../../api/axiosConfig";
import { PRACTCE_DETAILS_CONTROLER_URL } from "../../../api/Service";
import MapContainer from "../../../components/Map/MapContainer";
import { Toaster, toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/auth/AuthSlice";
import { removeuser } from "../../../redux/user/userSlice";
import { logoutFromHelper } from "../../../api/Helper";
import { useNavigate } from "react-router-dom";
import { useAdminView } from "../../../hooks/useAdminView";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const PracticeDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useAdminView();

  const formik = useFormik({
    initialValues: {
      name: "",
      country: "",
      type: "",
      county: "",
      city: "",
      address: "",
      address2: "",
      address3: "",
      eircode: "",
      mainPhone: "",
      practiceEmail: "",
      website: "",
      twitter: "",
      smsName: "",
      facebook: "",
      id: "",
      addressId: "",
      latitude: "",
      longitude: "",
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .min(2, "Too Short!")
        .max(150, "Too Long!")
        .required("This field is required"),
      country: yup.string().required("This field is required"),
      city: yup.string().optional(),
      county: yup.string().required("This field is required"),
      eircode: yup.string().required("This field is required"),
      address: yup.string().required("This field is required"),
      type: yup.string().required("Practice Type is required"),

      mainPhone: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Mobile number is required"),
      practiceEmail: yup
        .string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
    }),
    onSubmit: (state) => handleSubmit(state),
  });

  let tenantVal = useSelector((state) => state.auth.user?.tenant);
  if (!tenantVal) tenantVal = localStorage.getItem("kinesin-tenant");
  const [selectedImage, setSelectedImage] = useState(null);
  const [displayImage, setDisplayImage] = useState(null);
  const fileInputRef = useRef(null);

  const handleCameraIconClick = () => {
    fileInputRef.current.click();
  };

  const [image, setImage] = useState(null);
  const [base64Image, setBase64Image] = useState("");
  const handleFileSelection = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
    }

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setBase64Image(e.target.result);
      };

      reader.readAsDataURL(file);

      setImage(file);
    }
  };

  const handleSubmit = async (state) => {
    try {
      const axiosInstance = createAxiosInstance();

      const address = {
        id: state.addressId,
        tenant: tenantVal,
        latitude: state.latitude,
        longitude: state.longitude,
        addressLine1: state.address,
        addressLine2: state.address2,
        addressLine3: state.address3,
        city: state.city,
        postCode: state.eircode,
        county: state.county,
        country: state.country,
      };
      const {
        name,
        type,
        mainPhone,
        practiceEmail,
        website,
        twitter,
        smsName,
        facebook,
        id,
        addressId,
      } = state;
      const requestData = {
        name,
        description: type,
        mainPhone,
        practiceEmail,
        website,
        twitter,
        smsName,
        facebook,
        tenant: tenantVal,
        practicePicture: base64Image,
        address: address,
        phone2: "",
        outOfHoursPhone: "",
        addressId,
        id,
      };

      await axiosInstance.post(
        `${tenantVal}${PRACTCE_DETAILS_CONTROLER_URL}`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (formik.values.id) {
        toast.success("Details updated successfully");
      } else {
        toast.success("New Details created successfully");
      }
    } catch (error) {
      errorHandling(error);
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const axiosInstance = createAxiosInstance();
        const response = await axiosInstance.get(
          `${tenantVal}${PRACTCE_DETAILS_CONTROLER_URL}`
        );
        const practiceData = response.data;
        const imageData = practiceData[0].practicePicture;
        setBase64Image(imageData);
        setDisplayImage(imageData);
        if (practiceData !== undefined && practiceData.length > 0) {
          formik.setFieldValue("name", practiceData[0].name);
          formik.setFieldValue("mainPhone", practiceData[0].mainPhone);
          formik.setFieldValue("type", practiceData[0].description);
          formik.setFieldValue("practiceEmail", practiceData[0].practiceEmail);
          formik.setFieldValue("facebook", practiceData[0].facebook);
          formik.setFieldValue("website", practiceData[0].website);
          formik.setFieldValue("smsName", practiceData[0].smsName);
          formik.setFieldValue("twitter", practiceData[0].twitter);
          formik.setFieldValue("id", practiceData[0].id);
          formik.setFieldValue("addressId", practiceData[0].addressId);

          if (
            practiceData[0].address &&
            practiceData[0].address.postCode !== undefined
          )
            formik.setFieldValue("eircode", practiceData[0].address.postCode);
          if (
            practiceData[0].address &&
            practiceData[0].address.addressLine1 !== undefined
          )
            formik.setFieldValue(
              "address",
              practiceData[0].address.addressLine1
            );
          if (
            practiceData[0].address &&
            practiceData[0].address.addressLine2 !== undefined
          )
            formik.setFieldValue(
              "address2",
              practiceData[0].address.addressLine2
            );
          if (
            practiceData[0].address &&
            practiceData[0].address.addressLine3 !== undefined
          )
            formik.setFieldValue(
              "address3",
              practiceData[0].address.addressLine3
            );
          if (
            practiceData[0].address &&
            practiceData[0].address.city !== undefined
          )
            formik.setFieldValue("city", practiceData[0].address.city);
          if (
            practiceData[0].address &&
            practiceData[0].address.country !== undefined
          )
            formik.setFieldValue("country", practiceData[0].address.country);
          if (
            practiceData[0].address &&
            practiceData[0].address.county !== undefined
          )
            formik.setFieldValue("county", practiceData[0].address.county);
        }
      } catch (error) {
        errorHandling(error);
      }
    };
    fetchDetails();
  }, [tenantVal]);

  const handleEircodeChange = (newEircode) => {
    formik.setFieldValue("eircode", newEircode);
  };
  const handleLocationChange = (newLocation) => {
    formik.setFieldValue("latitude", newLocation.lat);
    formik.setFieldValue("longitude", newLocation.lng);
  };
  console.log("formik latitude", formik.values.latitude);
  console.log("formik longtitude", formik.values.longitude);

  const errorHandling = (error) => {
    console.log(error);
    toast.error(error.message);
    if (error && error.response && error.response.status) {
      if (error.response.status === 403) {
        logoutFromHelper();
        dispatch(logout());
        dispatch(removeuser());
        navigate("/logout");
      }
    }
  };

  return (
    <div>
      <div className="dashBcontbody">
        <div className="signup_threebox practice_detailssect mt-0">
          <Tab />
          <div className="tab-content ptdetalistabcont" id="pills-tabContent">
            <div className=" show active sectflex">
              <div className="dtl_uploadimgsect">
                <div className="upimgbox" onClick={handleCameraIconClick}>
                  <button type="button" className="imgbtnup">
                    <img src={camera} alt="" />
                  </button>

                  {base64Image ? (
                    <div className="cercelimg">
                      <img
                        // src={URL.createObjectURL(selectedImage)}
                        src={base64Image}
                        className="selected-img "
                      />
                    </div>
                  ) : (
                    <div className="cercelimg">
                      <img src={displayImage} className="selected-img" />
                    </div>
                  )}
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={handleFileSelection}
                />
              </div>

              <div className="tabdtformsect signup_threebox addpatientlist">
                <form
                  name="form"
                  onSubmit={formik.handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                >
                  <div className="row">
                    <div className="col-xl-6 mb-3">
                      <label className="requiredValidator">Practice Name</label>
                      <input
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Practice Name"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.name}
                      />
                      {formik.errors.name && formik.touched.name ? (
                          <div className="errorstext">{formik.errors.name}</div>
                      ) : null}
                    </div>
                    <div className="col-xl-6 mb-3 formfloating">
                      <label for="floatingSelect" className="requiredValidator">
                        Practice Type
                      </label>
                      <select
                          as="select"
                          className="form-select form-control custom-select"
                          id="type"
                          name="type"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.type}
                      >
                        <option>Select</option>
                        <option value="General Physician">
                          General Physician
                        </option>
                        <option value="Dentist">Dentist</option>
                        <option value="Physio">Physio</option>
                      </select>
                      {formik.errors.type && formik.touched.type ? (
                          <div className="errorstext">{formik.errors.type}</div>
                      ) : null}
                    </div>
                    <div className="col-md-12">
                      <label className="requiredValidator">
                        Practice Address
                      </label>
                    </div>
                    <div className="col-xl-4 mb-3">
                      <input
                          name="address"
                          type="text"
                          className="form-control"
                          placeholder="First line"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.address}
                      />
                      {formik.errors.address && formik.touched.address ? (
                          <div className="errorstext">
                            {formik.errors.address}
                          </div>
                      ) : null}
                    </div>
                    <div className="col-xl-4 mb-3">
                      <input
                          name="address2"
                          type="text"
                          className="form-control"
                          placeholder="Second line"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.address2}
                      />
                    </div>
                    <div className="col-xl-4 mb-3">
                      <input
                          name="address3"
                          type="text"
                          className="form-control"
                          placeholder="Third line"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.address3}
                      />
                    </div>
                    <div className="col-xl-6 mb-3 formfloating">
                      <label for="floatingSelect" className="requiredValidator">
                        County
                      </label>
                      <select
                          className="form-select form-control"
                          as="select"
                          id="county"
                          name="county"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.county}
                      >
                        <option>Select</option>
                        <option
                            style={{padding: "18px !important"}}
                            value="antrim"
                        >
                          Antrim
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="armagh"
                        >
                          Armagh
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="carlow"
                        >
                          Carlow
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="cavan"
                        >
                          Cavan
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="clare"
                        >
                          Clare
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="cork"
                        >
                          Cork
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="derry"
                        >
                          Derry
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="donegal"
                        >
                          Donegal
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="down"
                        >
                          Down
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="dublin"
                        >
                          Dublin
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="fermanagh"
                        >
                          Fermanagh
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="galway"
                        >
                          Galway
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="kerry"
                        >
                          Kerry
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="kildare"
                        >
                          Kildare
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="kilkenny"
                        >
                          Kilkenny
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="laois"
                        >
                          Laois
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="leitrim"
                        >
                          Leitrim
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="limerick"
                        >
                          Limerick
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="longford"
                        >
                          Longford
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="louth"
                        >
                          Louth
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="mayo"
                        >
                          Mayo
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="meath"
                        >
                          Meath
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="monaghan"
                        >
                          Monaghan
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="offaly"
                        >
                          Offaly
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="roscommon"
                        >
                          Roscommon
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="sligo"
                        >
                          Sligo
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="tipperary"
                        >
                          Tipperary
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="tyrone"
                        >
                          Tyrone
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="waterford"
                        >
                          Waterford
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="westmeath"
                        >
                          Westmeath
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="wexford"
                        >
                          Wexford
                        </option>
                        <option
                            style={{padding: "18px !important"}}
                            value="wicklow"
                        >
                          Wicklow
                        </option>
                      </select>
                      {formik.errors.county && formik.touched.county ? (
                          <div className="errorstext">{formik.errors.county}</div>
                      ) : null}
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label className="requiredValidator">City</label>
                      <input
                          name="city"
                          type="text"
                          className="form-control"
                          placeholder="City"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.city}
                      />
                      {formik.errors.city && formik.touched.city ? (
                          <div className="errorstext">{formik.errors.city}</div>
                      ) : null}
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label className="requiredValidator">Country</label>
                      <input
                          name="country"
                          type="text"
                          className="form-control"
                          placeholder="Country"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.country}
                      />
                      {formik.errors.country && formik.touched.country ? (
                          <div className="errorstext">
                            {formik.errors.country}
                          </div>
                      ) : null}
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label className="requiredValidator">Eircode</label>
                      <input
                          name="eircode"
                          type="text"
                          className="form-control"
                          placeholder="Eircode"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.eircode}
                      />
                      {formik.errors.eircode && formik.touched.eircode ? (
                          <div className="errorstext">
                            {formik.errors.eircode}
                          </div>
                      ) : null}
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label className="requiredValidator">
                        Practice phone
                      </label>
                      <input
                          name="mainPhone"
                          type="text"
                          className="form-control"
                          placeholder="Phone"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.mainPhone}
                      />
                      {formik.errors.mainPhone && formik.touched.mainPhone ? (
                          <div className="errorstext">
                            {formik.errors.mainPhone}
                          </div>
                      ) : null}
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label className="requiredValidator">
                        Practice email
                      </label>
                      <input
                          name="practiceEmail"
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.practiceEmail}
                      />
                      {formik.errors.practiceEmail &&
                      formik.touched.practiceEmail ? (
                          <div className="errorstext">
                            {formik.errors.practiceEmail}
                          </div>
                      ) : null}
                    </div>
                    <div className="col-xl-12 mb-3">
                      <label>Practice Website</label>
                      <input
                          name="website"
                          type="text"
                          className="form-control"
                          placeholder="Website"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.website}
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label>Sms name</label>

                      <input maxlength="11"
                          name="smsName"
                          type="text"
                          className="form-control"
                          placeholder="Sms Name"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.smsName}
                      />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label>External Social Links</label>
                      <input
                          name="twitter"
                          type="text"
                          className="form-control"
                          placeholder="twitter"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.twitter}
                      />
                      {formik.errors.twitter && formik.touched.twitter ? (
                          <div className="errorstext">
                            {formik.errors.twitter}
                          </div>
                      ) : null}
                    </div>
                    <div className="col-xl-12 mt-3">
                      <div className="btnxscenter">
                        <button className="custom_btn savwidth" type="submit">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="locationdetails">
                <MapContainer
                    eircode={formik.values.eircode}
                    onEircodeChange={handleEircodeChange}
                    onLocationChange={handleLocationChange}
                />
              </div>
            </div>
          </div>
        </div>
        <Toaster position="top-center" reverseOrder={false}/>
      </div>
    </div>
  );
};

export default PracticeDetails;
