import { Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Footer from "./components/Footer";
import Header from "./components/Header/Header";
import LogIn from "./view/pages/LogInAuthentication/LogIn";
import SignUp from "./view/pages/SignUpAuthentication/SignUpHealthPratice";
import ForgotPassword from "./view/pages/LogInAuthentication/LostPassword";
import AuthCode from "./view/pages/LogInAuthentication/AuthCode";
import Success_msg from "./view/pages/LogInAuthentication/Success_msg";
import ResetPasswordEmail from "./view/pages/LogInAuthentication/ResetPasswordEmail";
import AuthError from "./components/AuthError";
import Registration from "./view/pages/LogInAuthentication/Registration";
import SignUp_steptwo from "./view/pages/SignUpAuthentication/SignUp_steptwo";
import Home from "./view/pages/LogInAuthentication/LogIn";
import Reg_Complete from "./view/pages/PatientSignup/Reg_Complete";
import PatientRegistration from "./view/pages/PatientSignup/PatientRegistration";
import SignUp_stepthree from "./view/pages/SignUpAuthentication/SignUp_stepthree";
import SignUp_stepfour from "./view/pages/SignUpAuthentication/SignUp_stepfour";
import SignUp_stepfive from "./view/pages/SignUpAuthentication/SignUp_stepfive";
import SignUp_stepsix from "./view/pages/SignUpAuthentication/SignUp_stepsix";
import "./assets/css/responsive.css";

import Logout from "./view/pages/SampleSite/Logout";
import RouteView from "./route";
import "./App.css";
import { ChatWidget } from "./components/ChatWidget/chat-widget";

const queryClient = new QueryClient();

function App() {
  let accessToken;
  const isAuthenticated = localStorage.getItem("kinesin-isAuthenticated");

  if (isAuthenticated == "true") {
    accessToken = localStorage.getItem("kinesin-accessToken");
    console.log("access token (app.js, local storage) : " + accessToken);

    sessionStorage.setItem(
      "accessToken",
      localStorage.getItem("kinesin-accessToken")
    );
    sessionStorage.setItem(
      "refreshToken",
      localStorage.getItem("kinesin-refreshToken")
    );
    sessionStorage.setItem("userId", localStorage.getItem("kinesin-userId"));
    sessionStorage.setItem(
      "requestId",
      localStorage.getItem("kinesin-requestId")
    );
  }

  /**
   *
   */
  let role;
  if (accessToken) {
    role = JSON.parse(window.atob(accessToken.split(".")[1])).role;
  }

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/auth_code" element={<AuthCode />} />
          <Route path="/auth_error" element={<AuthError />} />
          <Route path="/reset_password/" element={<ResetPasswordEmail />} />
          <Route path="/success_msg" element={<Success_msg />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signup_two" element={<SignUp_steptwo />} />
          <Route path="/signup_three" element={<SignUp_stepthree />} />
          <Route path="/signup_four" element={<SignUp_stepfour />} />
          <Route path="/signup_five" element={<SignUp_stepfive />} />
          <Route path="/signup_six" element={<SignUp_stepsix />} />
          <Route path="/patient-signup" element={<PatientRegistration />} />
          <Route path="/regestration-complete" element={<Reg_Complete />} />
          {isAuthenticated ? <Route path="*" element={<RouteView />} /> : null}
        </Routes>
        {isAuthenticated && <ChatWidget />}
        {!isAuthenticated && <Footer />}
      </QueryClientProvider>
    </>
  );
}

export default App;
