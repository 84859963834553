import React, { useState } from "react";
import { useOpenPatientSummaryPage } from "../../../../../Utils/openPatientSummaryPage";
import { ConsultationsTable } from "../../../../pages/PatientGp/PatientConsultations/components/ConsultationsTable/ConsultationsTable";
import s from "./PatientInformation.module.css";
import { AddTaskForm } from "../../../../../components/AddTaskForm/AddTaskForm";
import Modal from "../../../../../components/helpers/Modal/Modal";

const getAge = (dateOfBirth) => {
  if (!dateOfBirth) return null;

  const dob = new Date(dateOfBirth);
  const diffMs = Date.now() - dob.getTime();
  const ageDt = new Date(diffMs);
  return Math.abs(ageDt.getUTCFullYear() - 1970);
};

const PatientInformation = ({ selectedPatient, selectedAppointment }) => {
  const openPatientSummaryPage = useOpenPatientSummaryPage();

  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false);

  const displayAge = (dob) => {
    if (!dob) return "";

    let aa = dob.split("-");
    if (aa && aa.length == 3) {
      return aa[2] + "/" + aa[1] + "/" + aa[0];
    } else {
      return dob;
    }
  };

  const ParagraphWithHover = ({ text }) => {
    const [showFullText, setShowFullText] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    const words = text.split(" ");

    const wordLimit = 15;
    const firstTwoLinesWords = words.slice(0, wordLimit);
    const remainingWords = words.slice(wordLimit);

    // Function to handle hover and show tooltip
    const handleMouseOver = () => {
      setShowTooltip(true);
    };

    // Function to handle mouse leave and hide tooltip
    const handleMouseLeave = () => {
      setShowTooltip(false);
    };
    return (
      <h6 onMouseEnter={handleMouseOver} onMouseLeave={handleMouseLeave}>
        {showFullText ? (
          text
        ) : (
          <>
            {firstTwoLinesWords.join(" ")}
            {remainingWords.length > 0 && "..."}
            {showTooltip && <span className="hover-text">{text}</span>}
          </>
        )}
      </h6>
    );
  };

  return (
    <>
      <div className={s.container}>
        <div className={s.header}>
          <div className={s.profileImg}>
            {selectedPatient &&
            selectedPatient.firstName.trim() &&
            selectedPatient.surname.trim()
              ? selectedPatient.firstName.trim()[0] +
                selectedPatient.surname.trim()[0]
              : (selectedPatient && selectedPatient.firstName.trim()
                  ? selectedPatient.firstName.trim()[0]
                  : "") +
                (selectedPatient && selectedPatient.surname.trim()
                  ? selectedPatient.surname.trim()[0]
                  : "")}
          </div>
          <div className={s.headerContent}>
            <div className={s.headerTextContainer}>
              <h3
                className={s.patientName}
                onClick={() => {
                  openPatientSummaryPage(
                    selectedPatient.id,
                    selectedPatient.firstName
                      ? selectedPatient.firstName +
                          " " +
                          selectedPatient.surname
                      : ""
                  );
                }}
              >
                {`${selectedPatient.firstName} ${
                  selectedPatient.surname
                } (${getAge(selectedPatient.dateOfBirth)})`}
              </h3>
              <p className={s.patientDOB}>
                DOB {displayAge(selectedPatient.dateOfBirth)}
              </p>
            </div>
            <div className={s.buttonsContainer}>
              <button
                className={s.outlinedButton}
                data-bs-toggle="modal"
                data-bs-target="#sendSma_modal"
                onClick={(e) => e.stopPropagation()}
              >
                Send SMS
              </button>
              <button
                className={s.outlinedButton}
                onClick={(e) => setIsAddTaskModalOpen(true)}
              >
                Add task
              </button>
            </div>
          </div>
        </div>

        <div className={s.horizontalDivider} />

        <div className={s.detailsContainer}>
          <div className={s.detailsCol}>
            <div className={s.detailsRow}>
              <h5>Phone no:</h5>
              <p>{selectedPatient.homePhone || "-"}</p>
            </div>
            <div className={s.detailsRow}>
              <h5>Address:</h5>
              <p>
                {selectedPatient && selectedPatient.address
                  ? selectedPatient.address.addressLine1 ||
                    selectedPatient.address.addressLine2 ||
                    selectedPatient.address.addressLine3
                    ? (selectedPatient.address.addressLine1
                        ? selectedPatient.address.addressLine1 + " "
                        : "") +
                      (selectedPatient.address.addressLine2
                        ? selectedPatient.address.addressLine2 + " "
                        : "") +
                      (selectedPatient.address.addressLine3
                        ? selectedPatient.address.addressLine3 + " "
                        : "")
                    : "-"
                  : "-"}
              </p>
            </div>
            <div className={s.detailsRow}>
              <h5>Medical card no.:</h5>
              <p>
                {selectedPatient.medicalCardNumber
                  ? selectedPatient.medicalCardNumber
                  : "-"}
              </p>
            </div>
          </div>

          <div className={s.verticalDivider} />

          <div className={s.detailsCol}>
            <div className={s.detailsRow}>
              <h5>Reason for visit:</h5>
              <p>
                {selectedPatient.nextAppointmentObj &&
                selectedPatient.nextAppointmentObj.reasonDescription ? (
                  <ParagraphWithHover
                    text={selectedPatient.nextAppointmentObj.reasonDescription}
                  />
                ) : (
                  "-"
                )}
              </p>
            </div>

            <div className={s.detailsRow}>
              <h5>Upcoming appointments:</h5>
              <p>{selectedPatient.nextApppointment ?? "-"}</p>
            </div>
          </div>

          <div
            className="clinicalNotesect"
            style={{ gridColumn: "1 / span 3" }}
          >
            <div className="title-wrap d-flex align-items-center justify-content-between">
              <h3>Recent consultations:</h3>
            </div>
            <ConsultationsTable
              patientIdVal={selectedPatient.id}
              itemsPerPage={5}
            />
          </div>
        </div>
      </div>

      {isAddTaskModalOpen && (
        <Modal
          title="Add Task"
          position="center"
          onClose={() => setIsAddTaskModalOpen(false)}
          hideCancel={true}
          width="800px"
        >
          <AddTaskForm
            patient={selectedPatient}
            closeModal={() => setIsAddTaskModalOpen(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default PatientInformation;
