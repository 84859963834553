import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";
import toast from "react-hot-toast";

export const useConsultationsPaginatedList = ({
  patientId,
  page,
  search,
  rows,
}) => {
  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error fetching consultations: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["consultationsList", patientId, tenantVal, page, search, rows],
    queryFn: async () => {
      if (!patientId) return null;

      const axiosInstance = await createAxiosInstance();
      const urlConsult =
        search !== undefined && search.length > 0
          ? `${tenantVal}/consultation/getbypatientid/${patientId}/details/search/page/${search}?page=${page}&rows=${rows}`
          : `${tenantVal}/consultation/getbypatientid/${patientId}/details/search/page?page=${page}&rows=${rows}`;
      const response = await axiosInstance.get(urlConsult);
      return response.data;
    },
    enabled: !!patientId,
    staleTime: 1000 * 60 * 5,
    onError: handleNetworkError,
  });
};

export const useMyConsultationsPaginatedList = ({ page, search }) => {
  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error fetching my consultations: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["consultationsList", tenantVal, page, search],
    queryFn: async () => {
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.get(
        search.length < 3
          ? `${tenantVal}/consultation/myconsultations/open/page?page=${page}`
          : `${tenantVal}/consultation/myconsultations/open/search/page/${search}?page=${page}`
      );
      return response.data;
    },
    staleTime: 1000 * 60 * 5,
    onError: handleNetworkError,
  });
};
