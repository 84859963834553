import { useRef } from "react";

import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { ScrollArea } from ".//ui/scroll-area";

import { useScrollChatToNewestMessage } from "../hooks/use-scroll-chat-to-newest-message";
import { useGetChat } from "../hooks/use-get-chat";
import { useSelector } from "react-redux";
import { useStaffMembers } from "../../../hooks/useStaffMembers";

export const ChatMessages = ({ currentView, activeChatStaffId }) => {
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");
  const messagesEndRef = useRef(null);
  const scrollAreaRef = useRef(null);

  const { data: staffMember } = useStaffMembers(tenantVal);

  const chatPartner = staffMember?.find(
    (staff) => staff.id === activeChatStaffId
  );

  const { handleScroll } = useScrollChatToNewestMessage(
    messagesEndRef,
    currentView,
    scrollAreaRef
  );

  const { data: chat } = useGetChat(activeChatStaffId);

  return (
    <ScrollArea
      style={{
        height: "368px",
      }}
      onScroll={handleScroll}
      ref={scrollAreaRef}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
          padding: "0.75rem",
        }}
      >
        {chat?.map((message) => {
          const isMessageYours =
            message?.assignToStaff?.tenant === Number(tenantVal);

          return (
            <div
              key={message.id}
              style={{
                display: "flex",
                justifyContent: isMessageYours ? "flex-end" : "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  maxWidth: "80%",
                  gap: "0.75rem",
                  flexDirection: isMessageYours ? "row-reverse" : "row",
                }}
              >
                <Avatar style={{ height: "2.8rem", width: "2.8rem" }}>
                  <AvatarImage
                    src={message.avatar}
                    alt={isMessageYours ? "you" : "chat pratner"}
                  />
                  <AvatarFallback>
                    {isMessageYours
                      ? "You"
                      : `${chatPartner?.firstName?.charAt(
                          0
                        )}${chatPartner?.lastName?.charAt(0)}`.toUpperCase()}
                  </AvatarFallback>
                </Avatar>

                <div
                  style={{
                    borderRadius: "0.5rem",
                    padding: "0.75rem",
                    fontSize: "0.875rem",
                    backgroundColor: isMessageYours ? "#065f46" : "#f3f4f6",
                    color: isMessageYours ? "#ffffff" : "#000000",
                  }}
                >
                  {message.message}
                </div>
              </div>
            </div>
          );
        })}

        {false && (
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <div style={{ display: "flex", maxWidth: "80%", gap: "0.75rem" }}>
              <Avatar style={{ height: "2rem", width: "2rem" }}>
                <AvatarImage
                  src="/placeholder.svg?height=32&width=32"
                  alt="Assistant"
                />
                <AvatarFallback>AI</AvatarFallback>
              </Avatar>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "0.5rem",
                  backgroundColor: "#f3f4f6",
                  padding: "0.75rem",
                  fontSize: "0.875rem",
                }}
              >
                <div style={{ display: "flex", gap: "0.25rem" }}>
                  <div
                    style={{
                      height: "0.5rem",
                      width: "0.5rem",
                      borderRadius: "9999px",
                      backgroundColor: "rgba(107, 114, 128, 0.4)",
                      animation: "bounce 1s infinite",
                      animationDelay: "-0.3s",
                    }}
                  ></div>
                  <div
                    style={{
                      height: "0.5rem",
                      width: "0.5rem",
                      borderRadius: "9999px",
                      backgroundColor: "rgba(107, 114, 128, 0.4)",
                      animation: "bounce 1s infinite",
                      animationDelay: "-0.15s",
                    }}
                  ></div>
                  <div
                    style={{
                      height: "0.5rem",
                      width: "0.5rem",
                      borderRadius: "9999px",
                      backgroundColor: "rgba(107, 114, 128, 0.4)",
                      animation: "bounce 1s infinite",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
    </ScrollArea>
  );
};
