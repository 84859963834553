import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { ScrollArea, ScrollBar } from "./ui/scroll-area";

export function ChatList({ chats, onSelectChat }) {
  return (
    <ScrollArea
      style={{
        height: "442px",
      }}
    >
      <ScrollBar orientation="vertical" />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          padding: "0.5rem",
        }}
      >
        {chats.map((chat) => (
          <button
            key={chat.staffId}
            variant="ghost"
            style={{
              display: "flex",
              height: "auto",
              width: "100%",
              cursor: "pointer",
              justifyContent: "space-between",
              borderRadius: "0.5rem",
              padding: "0.5rem",
              textAlign: "left",
              transition: "background-color 0.2s",
              border: "none",
              backgroundColor: "transparent",
              ":hover": {
                backgroundColor: "#f5f5f5",
              },
            }}
            aria-label={`Open chat with ${chat.name}`}
            onClick={() => {
              onSelectChat(chat.staffId);
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Avatar style={{ height: "3rem", width: "3rem" }}>
                <AvatarImage
                  src={chat.profilePic}
                  alt={`${chat.staffMember.firstName} ${chat.staffMember.lastName}`}
                />
                <AvatarFallback>
                  {chat.staffMember.firstName.slice(0, 1).toUpperCase()}
                  {chat.staffMember.lastName.slice(0, 1).toUpperCase()}
                </AvatarFallback>
              </Avatar>
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  flex: 1,
                  overflow: "hidden",
                }}
              >
                <h3
                  style={{
                    fontSize: "1rem",
                    fontWeight: chat.hasUnreadMessages ? "bold" : "600",
                    color: chat.hasUnreadMessages ? "black" : "#6b7280",
                  }}
                >
                  {chat.staffMember.firstName} {chat.staffMember.lastName}
                </h3>
                <p
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "0.875rem",
                    fontWeight: chat.hasUnreadMessages ? "bold" : "normal",
                    color: chat.hasUnreadMessages ? "black" : "#9ca3af",
                  }}
                >
                  {chat.chats?.at(0)?.message || "No messages yet"}
                </p>
                {chat.hasUnreadMessages && (
                  <span
                    role="presentation"
                    style={{
                      position: "absolute",
                      right: "0.25rem",
                      top: "0.25rem",
                      height: "0.625rem",
                      width: "0.625rem",
                      borderRadius: "9999px",
                      backgroundColor: "#10b981",
                    }}
                  ></span>
                )}
              </div>
            </div>
          </button>
        ))}
      </div>
    </ScrollArea>
  );
}
