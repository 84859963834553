import { FileText, Trash } from "lucide-react";
import { useChangeConsulationMainService } from "../../../../../../../hooks/useChangeConsulationMainService";
import { useConfirmPayment } from "../../../../../../../hooks/useConfirmPayment";
import { useConsultationServices } from "../../../../../../../hooks/useConsultationServices";
import { useDeleteConsultationService } from "../../../../../../../hooks/useDeleteConsultationService";
import { useMainService } from "../../../../../../../hooks/useMainService";
import style from "./PaymentSection.module.css";
import CustomButton from "../../../../../../../components/helpers/CustomButton/CustomButton";
import { Image } from "react-bootstrap";
import DocIcon from "../../../../../../../assets/images/doc-icon.svg";

export const PaymentSection = ({
  consultation,
  withoutBorder,
  withoutDeleteButton,
}) => {
  const isPaid = consultation?.moneyOwed === false;

  const { data: mainService } = useMainService(
    consultation?.mainPracticeServiceId
  );

  const { data: consultationServices, isLoading: isPaymentsLoading } =
    useConsultationServices(consultation?.id);
  const deleteConsultationService = useDeleteConsultationService(
    consultation?.id
  );

  const confirmPaymentMutation = useConfirmPayment(consultation?.id);
  const changeMainService = useChangeConsulationMainService(consultation);

  const allConultationServices = [
    mainService ? { service: mainService, id: mainService?.id } : null,
    ...(consultationServices ?? []),
  ].filter(Boolean);

  const handleConfirmPayment = () => {
    confirmPaymentMutation.mutate();
  };

  return (
    <section
      className={
        withoutBorder
          ? style.paymentSectionWrapperWithoutBorder
          : style.paymentSectionWrapper
      }
    >
      <div className={style.paymentSection}>
        <h2 className={style.paymentHeader}>
          <span>Payment</span>
          <span>
            Total price: €
            {allConultationServices?.reduce(
              (acc, curr) => acc + curr?.service?.price,
              0
            ) ?? 0}
          </span>
        </h2>
        {isPaymentsLoading ? (
          <div className={style.paymentSectionLoading}>Loading payments...</div>
        ) : allConultationServices && allConultationServices.length > 0 ? (
          <>
            <ul className={style.paymentItems}>
              {allConultationServices.map((service, index) => (
                <li key={service?.id || index} className={style.paymentItem}>
                  <div className={style.serviceInfo}>
                    <div className={style.iconWrapper}>
                      <FileText size={20} />
                    </div>
                    <span>{service?.service?.name}</span>
                  </div>
                  <div className={style.priceControls}>
                    <div className={style.priceInputWrapper}>
                      <input
                        type="text"
                        value={`€${service?.service?.price}`}
                        className="form-control"
                        readOnly
                        disabled={isPaid}
                      />
                    </div>
                    {allConultationServices?.length &&
                      allConultationServices?.length > 1 &&
                      !isPaid &&
                      !withoutDeleteButton && (
                        <button
                          className={style.deleteButton}
                          onClick={async () => {
                            if (index === 0) {
                              await changeMainService.mutate(
                                allConultationServices?.at(1)?.serviceId
                              );
                              await deleteConsultationService.mutate(
                                allConultationServices?.at(1)?.id
                              );

                              return;
                            }
                            await deleteConsultationService.mutate(service.id);
                          }}
                          type="button"
                        >
                          <Trash size={20} />
                        </button>
                      )}
                  </div>
                </li>
              ))}
            </ul>
            <div className={style.paymentConfirmButton}>
              <CustomButton
                buttonHandle={() => handleConfirmPayment()}
                title={
                  isPaid
                    ? "Paid"
                    : confirmPaymentMutation.isPending
                    ? "Processing..."
                    : "Confirm payment"
                }
                buttonClassName={style.button}
                icon={false}
                disabled={confirmPaymentMutation.isPending || isPaid}
              />
            </div>
          </>
        ) : (
          <div className={style.paymentSectionNoPayment}>
            <Image src={DocIcon} alt="category" />
            <div>
              <h3>No Payment Details Yet</h3>
              <p>
                Payment information will appear here once the consultation
                starts.
              </p>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
