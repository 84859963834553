import { Drawer } from "@mui/material";
import { useState } from "react";
import styles from "./MyTasksModal.module.css";
import { X } from "lucide-react";
import navi1 from "../../../../assets/images/navi1.svg";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

import { TaskTable } from "./TasksTable/TaskTable";
import { ClosedTaskTable } from "./ClosedTasksTable/ClosedTaskTable";

import {
  MyConsultationsTable
} from "../../../../view/pages/PatientGp/PatientConsultations/components/ConsultationsTable/MyConsultationsTable";
import {SentTaskTable} from "./SentTasksTable/SentTaskTable";

export const MyTasksModal = ({ goToPatientDetails, goToPatientConsult, goToPatientConsultations }) => {
  const [tab, setTab] = useState("tasks");
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        className={`${styles.actionBtn} nav__link`}
        onClick={() => setOpen(true)}
      >
        <img src={navi1} alt="" />
        My Tasks
      </button>

      <Drawer open={open} onClose={() => setOpen(false)} anchor="right">
        <div className={styles.drawerContent}>
          <div className={styles.drawerHeader}>
            <h2>My Tasks</h2>
            <button
              onClick={() => setOpen(false)}
              className={styles.closeButton}
            >
              <X />
            </button>
          </div>
          <div className={styles.toggleWrapper}>
            <ToggleButtonGroup
              value={tab}
              exclusive
              onChange={(e) => setTab(e.target.value)}
              aria-label="patient overview navigation"
              className={styles.toggleBtnGroup}
            >
              <ToggleButton
                value="tasks"
                aria-label="Tasks"
                className={styles.toggleBtn}
                disableRipple
              >
                Tasks
              </ToggleButton>
              <ToggleButton
                value="consultations"
                aria-label="consultations"
                className={styles.toggleBtn}
                disableRipple
              >
                Consultations
              </ToggleButton>
              <ToggleButton
                  value="sent-items"
                  aria-label="Sent items"
                  className={styles.toggleBtn}
                  disableRipple
              >
                Sent Items
              </ToggleButton>
              <ToggleButton
                value="closed-tasks"
                aria-label="Closed tasks"
                className={styles.toggleBtn}
                disableRipple
              >
                Closed Tasks
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          {tab === "tasks" && (
            <TaskTable goToPatientDetails={goToPatientDetails} />
          )}
          {tab === "consultations" && (
            <div className={styles.consultationsTableWrapper}>
              <MyConsultationsTable goToPatientConsult={goToPatientConsult}/>
            </div>
          )}
          {tab === "sent-items" && (
              <div className={styles.consultationsTableWrapper}>
                <SentTaskTable goToPatientConsult={goToPatientConsult}/>
              </div>
          )}
          {tab === "closed-tasks" && (
            <ClosedTaskTable goToPatientDetails={goToPatientDetails} />
          )}
        </div>
      </Drawer>
    </>
  );
};
