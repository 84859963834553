import { useState } from "react";
import { Send } from "lucide-react";

import { CardFooter } from "./ui/card";
import styles from "../chat-widget.module.css";
import { useSendChatMessage } from "../hooks/use-send-chat-message";

export const ChatSendMessageForm = ({ chatStaffMemberId }) => {
  const [input, setInput] = useState("");

  const sendMessage = useSendChatMessage(chatStaffMemberId);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await sendMessage.mutate(input);
    setInput("");
  };

  return (
    <CardFooter
      style={{
        borderTop: "1px solid #e5e7eb",
        padding: "0.75rem",
      }}
    >
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          width: "100%",
          gap: "0.5rem",
          flexDirection: "row",
          marginBottom: "0",
          marginTop: "0.75rem",
        }}
      >
        <input
          className="form-control"
          style={{
            flex: 1,
            padding: "0.5rem",
            border: "1px solid #d1d5db",
            borderRadius: "0.375rem",
            outline: "none",
            width: "100%",
            minWidth: "auto",
            height: "3rem",
          }}
          placeholder="Type your message..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <button
          type="submit"
          style={{
            width: "3rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#ffffff",
            borderRadius: "0.375rem",
            transition: "background-color 0.2s",
            cursor: "pointer",
            padding: "0.5rem",
            height: "3rem",
            flexShrink: 0,
          }}
          className={styles.button}
          // disabled={isLoading || !input.trim()}
        >
          <Send style={{ height: "16px", width: "16px" }} />
          <span
            style={{
              position: "absolute",
              width: "1px",
              height: "1px",
              padding: "0",
              margin: "-1px",
              overflow: "hidden",
              clip: "rect(0, 0, 0, 0)",
              border: "0",
            }}
          >
            Send message
          </span>
        </button>
      </form>
    </CardFooter>
  );
};
