import { useState } from "react";
import moment from "moment";

import showMoreIcon from "../../../../../assets/images/show-more-icon.svg";
import pregnancyGeneralInformationIcon from "../../../../../assets/images/pregnancy/general.svg";
import pregnancyAnteNatalIcon from "../../../../../assets/images/pregnancy/ante-natal.svg";
import pregnancyPostNatalIcon from "../../../../../assets/images/pregnancy/post-natal.svg";
import pregnancyHeartIcon from "../../../../../assets/images/pregnancy/heart.svg";
import style from "./CdmSidebar.module.css";
import { views } from "../hooks/useView";

export const CdmItem = ({ pregnancy, view, setView }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className={`${style.pregnancySidebarItem} ${
        !isExpanded
          ? style.pregnancySidebarItemNotExpanded
          : style.pregnancySidebarItemExpanded
      }`}
    >
      <div className={style.pregnancySidebarItemHeaderWrapper}>
        <div className={style.pregnancySidebarItemHeaderInnerWrapper}>
          <img src={pregnancyHeartIcon} alt="heart"></img>
          <div className={style.pregnancySidebarItemHeaderTitleWrapper}>
            <h3>Pregnancy</h3>
            <p>
              {moment(pregnancy.firstConsultationDate).format("DD.MM.YYYY")}
            </p>
          </div>
          <button
            aria-label="show more"
            onClick={() => setIsExpanded((prev) => !prev)}
          >
            <img src={showMoreIcon} alt="showmore" />
          </button>
        </div>
      </div>
      {isExpanded && (
        <div className={style.pregnancySidebarItemButtonWrapper}>
          <button
            className={`${style.pregnancySidebarItemButton} ${
              view.pregnancyId === pregnancy.id && view.name === views.general
                ? style.pregnancySidebarItemButtonActive
                : ""
            }`}
            onClick={() =>
              setView({
                name: views.general,
                pregnancyId: pregnancy.id,
              })
            }
          >
            <img
              src={pregnancyGeneralInformationIcon}
              alt="general information"
            />
            General information
          </button>
          <button
            className={`${style.pregnancySidebarItemButton} ${
              view.pregnancyId === pregnancy.id && view.name === views.anteNatal
                ? style.pregnancySidebarItemButtonActive
                : ""
            }`}
            onClick={() =>
              setView({
                name: views.anteNatal,
                pregnancyId: pregnancy.id,
              })
            }
          >
            <img src={pregnancyAnteNatalIcon} alt="ante natal" />
            Ante Natal
          </button>
          <button
            className={`${style.pregnancySidebarItemButton} ${
              view.pregnancyId === pregnancy.id && view.name === views.postNatal
                ? style.pregnancySidebarItemButtonActive
                : ""
            }`}
            onClick={() =>
              setView({
                name: views.postNatal,
                pregnancyId: pregnancy.id,
              })
            }
          >
            <img src={pregnancyPostNatalIcon} alt="post natal" />
            Post Natal
          </button>
        </div>
      )}
    </div>
  );
};
