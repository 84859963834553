import { useMemo, useRef, useState } from "react";
import { ArrowLeft, MessageCircle, X } from "lucide-react";
import { createPortal } from "react-dom";
import { useOnClickOutside } from "usehooks-ts";

import { Card, CardContent, CardHeader, CardTitle } from "./components/ui/card";

import { ChatList } from "./components/chat-list";
import { ChatMessages } from "./components/chat-messages";
import { ChatSendMessageForm } from "./components/chat-send-message-form";
import { useGetChats } from "./hooks/use-get-chats";
import { AddNewChat } from "./components/add-new-chat";
import {
  cardContentStyle,
  cardHeaderStyle,
  cardStyle,
  cardTitleStyle,
  chatContainerStyle,
  fixedChatButtonStyle,
  // unreadBadgeStyle,
} from "./style";

export function ChatWidget() {
  const [currentChatId, setCurrentChatId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [currentView, setCurrentView] = useState("list");
  const chatRef = useRef(null);

  const { data: savedChats } = useGetChats();

  const [newChat, setNewChat] = useState();

  const addNewChat = (staffMember) => {
    setNewChat({
      staffMember: staffMember,
      staffId: staffMember.id,
      chats: [],
    });
  };

  const chats = useMemo(() => {
    if (newChat) {
      const isStaffIdExists = savedChats?.some(
        (chat) => chat.staffId === newChat.staffId
      );

      if (!savedChats?.length || savedChats?.length === 0) {
        return [newChat];
      }

      if (!isStaffIdExists) {
        return [...(savedChats || []), newChat];
      }
    }
    return savedChats || [];
  }, [savedChats, newChat]);

  useOnClickOutside(chatRef, () => setIsOpen(false));

  const handleSelectChat = (chatId) => {
    setCurrentChatId(chatId);
    setCurrentView("chat");
  };

  const handleBackToList = () => {
    setCurrentView("list");
    setCurrentChatId("");
  };

  // const unreadMessagesCount = mockChats.reduce(
  //   (acc, { hasUnreadMessages }) => (hasUnreadMessages ? acc + 1 : acc),
  //   0
  // );

  const currentChatPartner = chats?.find(
    (chat) => chat.staffId === currentChatId
  )?.staffMember;

  return (
    <>
      <button
        style={fixedChatButtonStyle}
        onClick={() => setIsOpen(!isOpen)}
        aria-label="Open chat"
      >
        <MessageCircle
          style={{ height: "20px", width: "20px" }}
          strokeWidth={2.5}
        />
        {/* {unreadMessagesCount >= 1 && (
          <span style={unreadBadgeStyle}>{unreadMessagesCount}</span>
        )} */}
      </button>
      {isOpen &&
        createPortal(
          <div
            ref={chatRef}
            style={{
              ...chatContainerStyle,
              transform: isOpen ? "translateY(0)" : "translateY(100%)",
            }}
          >
            <Card style={cardStyle}>
              <CardHeader style={cardHeaderStyle}>
                <CardTitle style={cardTitleStyle}>
                  {currentView === "chat" && (
                    <button
                      variant="ghost"
                      size="icon"
                      onClick={handleBackToList}
                      style={{
                        marginRight: "8px",
                        border: "none",
                        backgroundColor: "transparent",
                        width: "2.5rem",
                        height: "2.5rem",
                      }}
                      aria-label="Back to chat list"
                    >
                      <ArrowLeft style={{ height: "16px", width: "16px" }} />
                    </button>
                  )}
                  <span style={{ marginLeft: "8px" }}>
                    {currentView === "list"
                      ? "Chats"
                      : `Chat with ${currentChatPartner?.firstName} ${currentChatPartner?.lastName}`}
                  </span>
                </CardTitle>
                <button
                  variant="ghost"
                  size="icon"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    width: "2.5rem",
                    height: "2.5rem",
                  }}
                  onClick={() => setIsOpen(false)}
                  aria-label="Close chat"
                >
                  <X style={{ height: "16px", width: "16px" }} />
                </button>
              </CardHeader>

              <CardContent style={cardContentStyle}>
                {currentView === "list" ? (
                  <>
                    <AddNewChat addNewChat={addNewChat} chats={chats} />
                    <ChatList chats={chats} onSelectChat={handleSelectChat} />
                  </>
                ) : (
                  <ChatMessages
                    activeChatStaffId={currentChatId}
                    currentView={currentView}
                  />
                )}
              </CardContent>

              {currentView === "chat" && (
                <ChatSendMessageForm chatStaffMemberId={currentChatId} />
              )}
            </Card>
          </div>,
          document.body
        )}
    </>
  );
}
