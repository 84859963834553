import { useState } from "react";
import Modal from "../../../../components/helpers/Modal/Modal";
import styles from "../AlliedHome.module.css";
import deleticon from "../../../../assets/images/deleticon.svg";
import { ChangeStatusButton } from "./ChangeStatusButton";
import { getAppointmentStatusColor, getAppointmentStatusLabel } from "./utils";
import { useDeleteAppointment } from "../../../../hooks/useDeleteAppointment";
import s from "./AppointmentBox.module.css";

export const AppointmentBox = ({
  item,
  selectedAppointment,
  style,
  className,
  handleClick,
  handleDoubleClick,
  refetchAppointments,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const hasData = !!item.id;
  const isSelected = selectedAppointment?.id === item.id;

  const { mutate: deleteAppointment } = useDeleteAppointment(() => {
    refetchAppointments();
    setShowDeleteModal(false);
  });

  if (!hasData) {
    return (
      <li
        style={style}
        className={`${className} ${s.listItem}`}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        aria-label="add appointment"
        role="button"
        tabIndex="0"
      >
        <p className={s.noAppointment}>No appointment</p>
      </li>
    );
  }

  return (
    <>
      <li
        style={style}
        className={`${className} ${s.listItem}`}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
      >
        <div
          className={`${styles.appointmentBox} ${getAppointmentStatusColor(
            item?.appointmentStatus
          )} ${isSelected ? styles.active : ""}`}
        >
          <div className={s.appointmentBoxContentWrapper}>
            <div className={s.appointmentBoxContent}>
              <h5 className={s.appointmentBoxContentName}>
                {item.patient && item.patient.firstName
                  ? item.patient.firstName + " " + item.patient.surname
                  : ""}
              </h5>
              <h5 className={s.appointmentBoxContentType}>
                {item.practiceService.name}
              </h5>
            </div>
            <div className={s.appointmentBoxContentStatusWrapper}>
              <p className={s.appointmentBoxContentStatus}>
                {getAppointmentStatusLabel(item?.appointmentStatus)}
              </p>
              <ChangeStatusButton
                appointmentId={item.id}
                onSelect={refetchAppointments}
                deleteAppointment={() => setShowDeleteModal(true)}
              />
            </div>
          </div>
        </div>
        {showDeleteModal && (
          <Modal
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            description="Are you sure you want to delete this appointment?"
            onSave={() => deleteAppointment(item.id)}
            width="500px"
            saveBtnTitle="Yes"
          >
            <div className="modal-body">
              <div className={s.worningimg}>
                <img src={deleticon} alt="delete" />
              </div>
              <div className={s.deletext}>
                <h5>Are you sure you want to delete this appointment?</h5>
              </div>
            </div>
          </Modal>
        )}
      </li>
    </>
  );
};
