import React from "react";
import { AppointmentBox } from "../AppointmentBox";
import moment from "moment";
import toast from "react-hot-toast";
import $ from "jquery";
import { useOpenPatientSummaryPage } from "../../../../../Utils/openPatientSummaryPage";
import s from "./TimeTable.module.css";

const TimeTable = ({
  allTimes,
  indexAndPatient,
  refetchAppointments,
  getPatientById,
  selectedDate,
  selectedAppointment,
  setSelectedAppointment,
  setStartDateForAppointment,
  setStartTimeForAppointment,
  setSelectedDateForAppointment,
  calendarYear,
  calendarMonth,
  calendarDate,
  consultationLength,
  freeTime,
}) => {
  const openAddAppointmentModal = () => {
    var iscurrentDate = moment(selectedDate)?.isBefore(new Date(), "day");
    if (iscurrentDate) return;

    setTimeout(() => {
      $("#idButtonAddAppointmentPopup").trigger("click");
    }, 100);
  };

  const openPatientSummaryPage = useOpenPatientSummaryPage();

  return (
    <div className={s.container}>
      <div className={s.containerWrapperInner}>
        <div>
          <div className={s.freeTimeContainer}>
            <h6>
              {consultationLength}{" "}
              {consultationLength && consultationLength > 0
                ? "Consultations"
                : "Consultation"}{" "}
            </h6>
            <h6>
              {freeTime} {freeTime && freeTime > 0 ? "Free slots" : "Free slot"}
            </h6>
          </div>
          <div className={s.listContainer}>
            <div className={s.timebar}>
              {allTimes.map((time, index) => {
                const currentTime = moment();
                const selectedMoment = moment(selectedDate);
                const timeMoment = moment(time, "HH:mm");

                const roundedTime = moment().set({
                  hour: currentTime.hour(),
                  minute: Math.floor(currentTime.minute() / 15) * 15,
                  second: 0,
                });

                let timeClass = s.futureTime;

                if (selectedMoment.isBefore(currentTime, "day")) {
                  timeClass = s.pastTime;
                } else if (selectedMoment.isSame(currentTime, "day")) {
                  if (timeMoment.isBefore(roundedTime, "minute")) {
                    timeClass = s.pastTime;
                  } else if (timeMoment.isSame(roundedTime, "minute")) {
                    timeClass = s.currentTime;
                  }
                }

                return (
                  <div className={`${s.time} ${timeClass}`} key={index}>
                    {time}
                  </div>
                );
              })}
            </div>

            <ul className={s.list}>
              {allTimes.map(
                (list, index) =>
                  indexAndPatient?.[list]?.isDisplay && (
                    <AppointmentBox
                      key={index}
                      refetchAppointments={refetchAppointments}
                      className={
                        indexAndPatient?.[list]?.durationInMins
                          ? {
                              15: s.duration15,
                              30: s.duration30,
                              45: s.duration45,
                            }[indexAndPatient[list].durationInMins] ||
                            s.duration60
                          : s.duration15
                      }
                      handleClick={() => {
                        const patient = indexAndPatient?.[list]?.patient;
                        const reasonForVisit =
                          indexAndPatient?.[list]?.reasonForVisit;

                        if (patient?.id) {
                          getPatientById(patient.id, reasonForVisit ?? "");
                          setSelectedAppointment(indexAndPatient?.[list]);
                          return;
                        }

                        setStartDateForAppointment(
                          moment(selectedDate).format("YYYY-MM-DD")
                        );
                        setStartTimeForAppointment(allTimes[index]);
                        setSelectedDateForAppointment(new Date(selectedDate));

                        const appointmentTime = moment(
                          `${calendarYear}-${calendarMonth}-${calendarDate} ${allTimes[index]}`
                        );

                        if (appointmentTime.isBefore(moment())) {
                          toast.error(
                            "Oops! You cannot create an appointment for a time slot that has already passed."
                          );
                          return;
                        }

                        openAddAppointmentModal();
                      }}
                      handleDoubleClick={() => {
                        const patient = indexAndPatient?.[list]?.patient;

                        if (patient?.id) {
                          openPatientSummaryPage(
                            patient.id,
                            `${patient.firstName ?? ""} ${
                              patient.surname ?? ""
                            }`.trim()
                          );
                        }
                      }}
                      item={indexAndPatient[list]}
                      selectedAppointment={selectedAppointment}
                      indexAndPatient={indexAndPatient}
                    />
                  )
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeTable;
