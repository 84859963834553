import dayjs from "dayjs";
import React, { useEffect } from "react";
import { z } from "zod";
import { useForm, FormProvider } from "react-hook-form";
import { toast, Toaster } from "react-hot-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import "react-calendar/dist/Calendar.css";

import style from "./StartConsultationForm.module.css";
import { FormField } from "../../../../../../components/form/FormField/FormField";
import { useStartConsultation } from "../../../../../../hooks/useStartConsultation";
import { usePracticeServices } from "../../../../../../hooks/usePracticeServices";
import CustomCheckbox from "../../../../../../components/helpers/CustomCheckbox/CustomCheckbox";
import { useAppointment } from "../../../../../../hooks/useAppointment";

const formSchema = z.object({
  type: z.string().min(1, { message: "Enter the type of consultation." }),
  doctor: z.string().min(1, { message: "Enter the doctor's name." }),
  date: z.string().min(1, { message: "Enter the date of the consultation." }),
  shouldCorelateToTheScheduledAppointment: z.boolean(),
});

export const StartConsultationForm = ({
  closeModal,
  staffName,
  hasAppointment,
  appointmentId,
}) => {
  const patientId = localStorage.getItem("kinesin-patientId");
  const startConsultationMutation = useStartConsultation(patientId);
  const { data: appointment } = useAppointment(appointmentId);
  const { data: services } = usePracticeServices();
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      doctor: staffName,
      type: "",
      date: dayjs().format("DD.MM.YYYY, HH:mm"),
      shouldCorelateToTheScheduledAppointment: !!hasAppointment,
    },
  });

  const handleFormSubmit = async (values) => {
    startConsultationMutation.mutate(
      {
        serviceId: values.type,
        appointmentId: values?.shouldCorelateToTheScheduledAppointment
          ? appointmentId
          : undefined,
      },
      {
        onSuccess: (data) => {
          form.reset();
          closeModal();
        },
        onError: () => {
          toast.error("Oops, something went wrong!");
        },
      }
    );
  };

  const shouldCorelateToTheScheduledAppointment = form.watch(
    "shouldCorelateToTheScheduledAppointment"
  );

  useEffect(() => {
    if (
      (shouldCorelateToTheScheduledAppointment,
      appointment,
      appointment?.practiceServiceId)
    ) {
      form.setValue("type", appointment?.practiceServiceId?.toString());
    }
  }, [appointment, shouldCorelateToTheScheduledAppointment, form]);

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <FormProvider {...form}>
        <form
          className={style.consultationForm}
          onSubmit={form.handleSubmit(handleFormSubmit)}
        >
          <div className={style.inputGroupGrid}>
            <FormField name="doctor">
              <label className={style.formLabel}>Doctor</label>
              <input
                type="text"
                className="form-control"
                disabled
                {...form.register("doctor")}
              />
            </FormField>
            <FormField name="date">
              <div className={style.datePickerWrapper}>
                <label className={style.formLabel}>Date</label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  {...form.register("date")}
                />
              </div>
            </FormField>
          </div>
          <FormField name="type">
            <label className={style.formLabel}>Type</label>
            <select
              className="form-select form-control custom-select"
              {...form.register("type")}
              disabled={shouldCorelateToTheScheduledAppointment}
            >
              {(services ?? []).map((item) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </FormField>
          {hasAppointment && (
            <FormField name="shouldCorelateToTheScheduledAppointment">
              <label className={style.formLabel}>
                <CustomCheckbox
                  {...form.register("shouldCorelateToTheScheduledAppointment")}
                />
                Corelate to the scheduled appointment
              </label>
            </FormField>
          )}
          <div className={style.buttonRow}>
            <button
              className={style.cancelButton}
              onClick={closeModal}
              type="button"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={style.submitButton}
              disabled={startConsultationMutation.isLoading}
            >
              Start
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
