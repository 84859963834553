import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useStartConsultation = (patientId) => {
  const queryClient = useQueryClient();

  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error while starting consultation: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useMutation({
    mutationFn: async ({ serviceId, appointmentId }) => {
      const axiosInstance = createAxiosInstance();

      const response = await axiosInstance.post(
        `${tenantVal}/consultation/start`,
        {
          patientId: Number(patientId),
          startTime: new Date().toISOString(),
          startAt: new Date().toISOString(),
          tenant: Number(tenantVal),
          mainPracticeServiceId: Number(serviceId),
          appointmentId: appointmentId ? Number(appointmentId) : undefined,
        }
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("Consultation started successfully");
      queryClient.invalidateQueries();
    },
    onError: handleNetworkError,
  });
};
