import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";
import toast from "react-hot-toast";

export const usePatientPrescriptions = (passedPatientId) => {
  const patientId =
    passedPatientId || localStorage.getItem("kinesin-patientId");

  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error fetching patient prescriptions: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["patientPrescriptions", patientId, tenantVal],
    queryFn: async () => {
      if (!patientId) return null;

      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/patientprescription/getbypatientid/${patientId}`
      );
      return response.data;
    },
    enabled: !!patientId,
    onError: handleNetworkError,
  });
};
