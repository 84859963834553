import { useState } from "react";

import style from "./AddTaskModal.module.css";

import Modal from "../helpers/Modal/Modal";
import { AddTaskForm } from "../AddTaskForm/AddTaskForm";

export const AddTaskModal = ({
  patient,
  isDisabled,
  buttonClassName,
  task,
  modalTitle,
  buttonText,
  staffMemberId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <button
        className={buttonClassName || style.openModalButton}
        type="button"
        disabled={isDisabled}
        onClick={() => setIsModalOpen(true)}
      >
        {buttonText || "Add new"}
      </button>
      {isModalOpen && (
        <Modal
          title={modalTitle || "Add Task"}
          position="center"
          onClose={() => setIsModalOpen(false)}
          hideCancel={true}
          width="800px"
        >
          <AddTaskForm
            task={task}
            patient={patient}
            staffMemberId={staffMemberId}
            closeModal={() => setIsModalOpen(false)}
          />
        </Modal>
      )}
    </>
  );
};
