import React from "react";
import s from "./LongTermMedication.module.css";
import { Toaster } from "react-hot-toast";
import { DrugsTable } from "../DrugsTable/DrugsTable";
import { AddPrescriptionModal } from "../../../../../../components/AddPrescriptionModal/AddPrescriptionModal";
import { PlusIcon } from "lucide-react";

const LongTermMedication = ({
  medications,
  isLoading,
  selectedMedications,
  selectedRows,
  setSelectedRows, deleteDrug,
}) => {
  return (
    <div className={s.longTermWrapper}>
      <Toaster position="top-center" reverseOrder={false} />
      <div className={s.topWrapper}>
        <h3 className={s.title}>Long term medications</h3>

        {Object.keys(selectedRows).length > 0 &&
          Object.values(selectedRows).some((selected) => selected) && (
            <AddPrescriptionModal
              buttonIcon={<PlusIcon width={18} height={18} />}
              initialMedications={selectedMedications}
            />
          )}
      </div>
      <DrugsTable
        items={medications}
        isLoading={isLoading}
        tableName="longterm-medications"
        emptyTitle="No Long term prescriptions"
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        deleteDrug={deleteDrug}
      />
    </div>
  );
};

export default LongTermMedication;
