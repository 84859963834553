import { getPatientName } from "../../../utils";
import styles from "./SentTaskTable.module.css";
import { useState } from "react";
import { X, Loader2, UserIcon, CheckCircle2 } from "lucide-react";
import tick from "../../../../../assets/images/tick.svg";
import Delete from "../../../../../assets/images/delete.svg";
import { useDeleteTask } from "../../../../../hooks/useDeleteTask";
import { useCompleteTask } from "../../../../../hooks/useCompleteTask";

export const SentTableItem = ({ task, goToPatientDetails }) => {
  const deleteTask = useDeleteTask();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteClick = (taskId) => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    await deleteTask.mutateAsync(task.id);
    setShowDeleteModal(false);
  };

  const completeTask = useCompleteTask();

  const handleCompleteTask = async (taskId) => {
    await completeTask.mutateAsync(taskId);
  };

  return (
    <>
      <li
          key={task.id}
          className={`${styles.tableItem} ${
              task.status === "Complete" ? styles.completedRow : ""
          }`}
      >
        <p className={styles.patientCell}>
          {task.status === "Complete" && (
              <span className={styles.completedIcon}>
              <CheckCircle2 size={16}/>
            </span>
          )}
          {getPatientName(task.patient) || "N/A"}
        </p>
        <p
            className={`${styles.titleCell} ${
                task.status === "Complete" ? styles.completedText : ""
            }`}
        >
          {task.title}
        </p>
        <p
            className={`${styles.titleCell}`}
        >
          {task.status}
        </p>
        <div className={styles.actionsCell}>
          <button
              className={`${styles.actionButton} ${
                  task.status === "Complete" ? styles.completedAction : ""
              }`}
              aria-label="complete task"
              onClick={() => handleCompleteTask(task.id)}
              disabled={task.status === "Complete"}
          >
            <img src={tick} alt="Complete task"/>
          </button>
          <button
              onClick={() => handleDeleteClick(task.id)}
              className={styles.actionButton}
              aria-label="delete task"
              disabled={deleteTask.isPending}
          >
            <img src={Delete} alt="Delete task"/>
          </button>
          {/* <button
            onClick={() => setShowTaskDetailsModal(true)}
            className={`${styles.actionButton} ${
              task.status === "Complete" ? styles.completedAction : ""
            }`}
            disabled={task.status === "Complete"}
            aria-label="show task details"
          >
            <CircleEllipsis color={"#8d8da3"} width={18} height={18} />
          </button> */}
          <button
              onClick={() =>
                  goToPatientDetails(task.patient.id, getPatientName(task.patient))
              }
              className={styles.actionButton}
              aria-label="open user details"
              disabled={!task?.patient?.id}
          >
            <UserIcon color={"#8d8da3"} width={18} height={18}/>
          </button>
        </div>
      </li>
      {/* {showTaskDetailsModal && (
        <DisplayTaskModal
          closeModal={() => {
            if (setStaffMemberId) setStaffMemberId("");
            setShowModal(false);
          }}
          task={selectedTask}
          isOpen={showModal}
        />
      )} */}
      {showDeleteModal && (
        <div className={styles.modalOverlay}>
          <div className={styles.modal}>
            <div className={styles.modalHeader}>
              <h3>Delete Task</h3>
              <button
                onClick={() => setShowDeleteModal(false)}
                className={styles.modalCloseButton}
                disabled={deleteTask.isPending}
              >
                <X size={20} />
              </button>
            </div>
            <div className={styles.modalContent}>
              <p>Are you sure you want to delete this task?</p>
            </div>
            <div className={styles.modalActions}>
              <button
                onClick={() => setShowDeleteModal(false)}
                className={styles.cancelButton}
                disabled={deleteTask.isPending}
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDelete}
                className={styles.deleteButton}
                disabled={deleteTask.isPending}
              >
                {deleteTask.isPending ? (
                  <>
                    <Loader2 className={styles.spinner} size={16} />
                    Deleting...
                  </>
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
