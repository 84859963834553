import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import s from "./PatientCDM.module.css";
import NavigationBar from "../../PatientDetailGp/components/NavigationBar/NavigationBar.jsx";
import PatientCard from "../PatientCard/PatientCard.jsx";
import Modal from "../../../../components/helpers/Modal/Modal.jsx";
import { CdmSidebar } from "./CdmSidebar/CdmSidebar.jsx";
import { useQuery } from "@tanstack/react-query";
import { fetchPatientById } from "./utils/fetchPatientById.js";
import { useView, views } from "./hooks/useView.js";
import { PregnancyLoader } from "./components/PregnancyLoader/PregnancyLoader.jsx";
import { fetchChronicDiseasesByPatientId } from "./utils/fetchChronicDiseasesByPatientId.js";
import { NoCdmSection } from "./NoCdmSection/NoCdmSection.jsx";

const PatientCDM = () => {
  const patientId = localStorage.getItem("kinesin-patientId");
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const { isLoading, data: patient } = useQuery({
    queryKey: ["patient", patientId, tenantVal],
    queryFn: () => fetchPatientById(patientId, tenantVal),
  });

  const { isLoading: isCdmLoading, data: cdmData } = useQuery({
    queryKey: ["chronicDiseases", patientId, tenantVal],
    queryFn: () => fetchChronicDiseasesByPatientId(patientId, tenantVal),
  });

  const [isModalOpen, setIsModalOpen] = useState({ open: false, id: null });
  const [view, setView] = useView(cdmData);

  useEffect(() => {
    if (view.name === views.noChronicDiseases && cdmData?.at(0)?.id) {
      setView({
        name: views.general,
        pregnancyId: cdmData?.at(0)?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCdmLoading]);

  if (isCdmLoading) {
    return <PregnancyLoader>Loading Chronic Diseases...</PregnancyLoader>;
  }

  return (
    <>
      <NavigationBar />
      <div style={{ margin: "0 auto" }}>
        <div className={s.wrapper}>
          <div className={s.firstColumnWrapper}>
            {isLoading ? (
              <p>Patient Chronic diseases is loading...</p>
            ) : (
              <>
                <PatientCard patient={patient} />
                <CdmSidebar
                  setView={setView}
                  cdms={cdmData}
                  onAddCdmButtonClick={() => setIsModalOpen({ open: true })}
                  view={view}
                />
              </>
            )}
          </div>
          <div className={s.secondColumnWrapper}>
            <div className={s.secondColumnSubWrapper}>
              {view.name === views.noChronicDiseases && (
                <NoCdmSection
                  onAddCdmButtonClick={() => setIsModalOpen({ open: true })}
                />
              )}
              {view.name === views.general && <p>general</p>}
              {/* {view.name === views.anteNatal && <p>anteNatal</p>}
              {view.name === views.postNatal && <p>postNatal</p>} */}
            </div>
          </div>
        </div>
        {isModalOpen.open && (
          <Modal
            title="Add new review"
            position="center"
            onClose={() => setIsModalOpen({ open: false })}
            hideCancel={true}
            width="800px"
          >
            <p>Add new review</p>
          </Modal>
        )}
      </div>
    </>
  );
};

export default PatientCDM;
