import { useQuery } from "@tanstack/react-query";
import { createAxiosInstance } from "../api/axiosConfig";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../Utils/useHandleNetworkError";

export const useConsultationByAppointmentId = (appointmentId) => {
  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error fetching consultation: ${error.message}`)
  );
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["consultationByAppointmentId", appointmentId, tenantVal],
    queryFn: async () => {
      if (!appointmentId) return null;

      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/consultation/getbyappointmentid/${appointmentId}`
      );

      return response.data?.at(0) || undefined;
    },
    enabled: !!appointmentId,
    onError: handleNetworkError,
  });
};
