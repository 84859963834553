import React, {useEffect, useState} from "react";
import "../../../assets/css/allied_settings.css";
import Nav from "../../../components/AfterLoginNav/Nav";
import Tab from "../../../components/SettingsTab/Tab";
import {useSelector, useDispatch} from "react-redux";
import {createAxiosInstance} from "../../../api/axiosConfig";
import {HEALTHMAIL_CREDS_CONTROLER_URL, PATIENT_CONTROLER_URL} from "../../../api/Service";
import {toast} from "react-hot-toast";
import {logoutFromHelper} from "../../../api/Helper";
import {logout} from "../../../redux/auth/AuthSlice";
import { removeuser } from "../../../redux/user/userSlice";
import { useNavigate } from "react-router-dom";
import {postData} from "../../../components/API";
import moment from "moment/moment";

const HealthMailDetails = () => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [emailAddress, setEmailAddress]= useState("");

    const [credentials, setCredentials] = useState({});


    const navigate = useNavigate();
    const dispatch = useDispatch();

    let tenantVal = useSelector((state) => state.auth.user?.tenant);
    if (!tenantVal) tenantVal = localStorage.getItem("kinesin-tenant");

    const [formParam, setFormParam] = useState({
        errors: {
            email: "",
        },
        submitted: false,
    });

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const axiosInstance = createAxiosInstance();
                const response = await axiosInstance.get(
                    `${tenantVal}${HEALTHMAIL_CREDS_CONTROLER_URL}`
                );
                const creds = response.data;
                setCredentials(creds);
                setPassword(creds.password);
                setEmailAddress(creds.emailAddress)
            } catch (error) {
                errorHandling(error);
            }
        };
        fetchDetails();
    }, [tenantVal]);

    const handleCredSave = async (e) => {
        e.preventDefault();
        try {
            console.log(emailAddress)
            console.log(credentials)
            console.log(password)
            credentials.emailAddress = emailAddress;
            credentials.password = password;
            const response = await postData(`${tenantVal}${HEALTHMAIL_CREDS_CONTROLER_URL}`, credentials);
            toast.success("Health link credentials saved successfully!");
        } catch (error) {
            console.error("There was a problem with the axios operation:", error);
        }
    };

    const errorHandling = (error) => {
        console.log(error);
        toast.error(error.message);
        if (error && error.response && error.response.status) {
            if (error.response.status === 403) {
                logoutFromHelper();
                dispatch(logout());
                dispatch(removeuser());
                navigate("/logout");
            }
        }
    };



    return (
        <div>
            {/* <Nav /> */}
            <div className="dashBcontbody">
                <div className="signup_threebox practice_detailssect mt-0">
                    <Tab />
                    <div
                        className="tab-content ptdetalistabcont user_detailsboxtab"
                        id="pills-tabContent"
                    >
                        <div className="show active">
                            <div className="paymentsdetailsec">
                                <h5>Enter your health mail details here</h5>

                                <div className="modal-body servicemodalbox">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-4 mb-4">
                                                <label className="requiredValidator">Health mail email</label>
                                                <input
                                                    type="text"
                                                    placeholder="Email"
                                                    className=" form-control"
                                                    value={emailAddress}
                                                    onChange={(e) => setEmailAddress(e.target.value)}
                                                />

                                            </div>
                                            <div className="col-md-4 mb-4">
                                                <label className="requiredValidator">Password</label>
                                                <input
                                                    type="password"
                                                    placeholder="Password"
                                                    className=" form-control"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />

                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div className="btnxscenter">
                                    <button className="custom_btn savwidth" type="submit" onClick={handleCredSave}>
                                        Connect
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HealthMailDetails;
