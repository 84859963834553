import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHandleNetworkError } from "../../../Utils/useHandleNetworkError";
import { createAxiosInstance } from "../../../api/axiosConfig";

export const useSendChatMessage = (staffId) => {
  const queryClient = useQueryClient();

  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error sending chat message: ${error.message}`)
  );
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useMutation({
    mutationFn: async (message) => {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.post(`${tenantVal}/chat`, {
        assignToStaffId: Number(staffId),
        message,
        createdByStaffId: Number(tenantVal),
      });
      return response.data;
    },
    onSuccess: () => {
      toast.success("Service added successfully");
      queryClient.invalidateQueries(["chat", "chats"]);
    },
    onError: handleNetworkError,
  });
};
