import { CdmItem } from "./CdmItem";

import style from "./CdmSidebar.module.css";

export const CdmSidebar = ({ setView, view, cdms, onAddCdmButtonClick }) => {
  if (cdms?.length < 1) {
    return null;
  }

  return (
    <aside className={style.pregnancySidebar}>
      <button
        className={style.addPregnancyButton}
        onClick={onAddCdmButtonClick}
        type="button"
      >
        Add new review
      </button>
      {cdms.map((pregnancy) => (
        <CdmItem
          pregnancy={pregnancy}
          setView={setView}
          key={pregnancy.id}
          view={view}
        />
      ))}
    </aside>
  );
};
