import { useState, useCallback } from "react";
import { Loader2 } from "lucide-react";
import styles from "./ConsultationsTable.module.css";
import { useConsultationsPaginatedList } from "../../../../../../hooks/useConsultationsPaginatedList";
import srcicon from "../../../../../../assets/images/srcicon.svg";
import { ConsultationPreview } from "../../../../../../components/ConsultationPreview/ConsultationPreview";
import { usePracticeServices } from "../../../../../../hooks/usePracticeServices";
import { createDateFromArray } from "../../../../../../Utils/createDateFromArray";
import { Pagination } from "@mui/material";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";

export const ConsultationsTable = ({ patientIdVal, itemsPerPage = 10 }) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [page, setPage] = useState(1);
  const patientId =
    patientIdVal !== undefined
      ? patientIdVal
      : localStorage.getItem("kinesin-patientId");
  const { data, isLoading } = useConsultationsPaginatedList({
    patientId,
    page,
    search: debouncedSearch,
    rows: itemsPerPage,
  });
  const { data: services } = usePracticeServices();

  const totalPages = Math.ceil(data?.totalRecords / itemsPerPage);
  const start = (page - 1) * itemsPerPage + 1;
  const end = Math.min(page * itemsPerPage, data?.totalRecords);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce((query) => {
      setDebouncedSearch(query);
    }, 300),
    []
  );

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    debouncedSearchHandler(e.target.value);
  };

  const renderTableContent = () => {
    if (isLoading) {
      return (
        <div className={styles.emptyState}>
          <Loader2 className={styles.loadingSpinner} size={24} />
          <p className={styles.emptyStateTitle}>Loading data...</p>
          <p className={styles.emptyStateText}>
            Please wait while we fetch the consultations.
          </p>
        </div>
      );
    }

    if (!data?.data?.length) {
      return (
        <div className={styles.emptyState}>
          <p className={styles.emptyStateTitle}>No consultations found</p>
          <p className={styles.emptyStateText}>
            {search
              ? "Try adjusting your search to find what you're looking for."
              : "There are no consultations to display at this time."}
          </p>
        </div>
      );
    }

    return (
      <>
        <div className={styles.consultationPreviewWrapper}>
          {(data?.data || [])?.map((consultation) => (
            <ConsultationPreview
              onEditConsultation={() =>
                navigate(`/consultation/edit/${consultation.id}`)
              }
              consultation={{
                id: consultation.id,
                title:
                  consultation.patient?.firstName +
                    " " +
                    consultation.patient?.surname +
                    " - " +
                    services.find(
                      ({ id }) => id === consultation.mainPracticeServiceId
                    )?.name || "Consultation",
                date: createDateFromArray(consultation.created),
                consultationStaff: consultation.consultationStaff,
                patient: consultation.patient,
                attachments: {
                  prescriptions: consultation?.patientPrescriptions || [],
                  refferrals: [],
                },
              }}
              text={
                consultation.consultationNoteList?.at(0)?.note ||
                "No notes found for this consultation."
              }
              key={consultation.id}
            >
              {consultation.consultationNoteList?.at(0)?.createdByStaffMember
                ?.firstName && (
                <div className={styles.consultationDoctorSection}>
                  <h3>Doctor</h3>
                  <p>{`${
                    consultation.consultationNoteList?.at(0)
                      ?.createdByStaffMember?.firstName
                  } ${
                    consultation.consultationNoteList?.at(0)
                      ?.createdByStaffMember?.middleNames || ""
                  } ${
                    consultation.consultationNoteList?.at(0)
                      ?.createdByStaffMember?.lastName || ""
                  }`}</p>
                </div>
              )}
            </ConsultationPreview>
          ))}
        </div>
        <div className={styles.paginationWrapper}>
          <p className={styles.resultsInfo}>
            {start}-{end} of {data?.totalRecords} results
          </p>
          <Pagination
            count={totalPages}
            onChange={(_event, value) => {
              setPage(value);
            }}
            page={page}
          />
        </div>
      </>
    );
  };

  return (
    <div className={styles.tableWarpper}>
      <div className={styles.table}>
        <div className={styles.tableTopBarWrapper}>
          <div className={`${styles.searchWrapper} search_bar`}>
            <input
              type="search"
              className="form-control"
              placeholder="Search consultations"
              value={search}
              onChange={handleSearchChange}
            />
            <img src={srcicon} alt="" />
          </div>
        </div>
        {renderTableContent()}
      </div>
    </div>
  );
};
