import { useCallback, useEffect, useState } from "react";

export const useScrollChatToNewestMessage = (
  messagesEndRef,
  currentView,
  scrollAreaRef,
  messages
) => {
  const [userScrolled, setUserScrolled] = useState(false);
  const scrollToBottom = useCallback(
    (behavior = "smooth") => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior });
      }
    },
    [messagesEndRef]
  );

  useEffect(() => {
    if (currentView === "chat" && !userScrolled && messages?.length > 0) {
      scrollToBottom();
    }
  }, [currentView, scrollToBottom, userScrolled, messages]);

  useEffect(() => {
    if (currentView === "chat") {
      scrollToBottom("instant");
      setUserScrolled(false);
    }
  }, [currentView, scrollToBottom]);

  const handleScroll = () => {
    if (scrollAreaRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollAreaRef.current;
      const isScrolledToBottom = scrollHeight - scrollTop === clientHeight;
      setUserScrolled(!isScrolledToBottom);
    }
  };

  return { handleScroll };
};
