export const fixedChatButtonStyle = {
  position: "fixed",
  bottom: "1.25rem",
  right: "1.25rem",
  zIndex: 50,
  height: "3.5rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "3.5rem",
  padding: "0",
  borderRadius: "9999px",
  boxShadow:
    "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
  color: "white",
  backgroundColor: "var(--green-800)",
  border: "1px solid var(--green-800)",
  fontSize: "16px",
  cursor: "pointer",
  fontWeight: 600,
};

export const unreadBadgeStyle = {
  position: "absolute",
  bottom: "-0.2rem",
  right: "-0.2rem",
  display: "flex",
  height: "1.25rem",
  width: "1.25rem",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "9999px",
  backgroundColor: "#ef4444",
  fontSize: "0.75rem",
};

export const chatContainerStyle = {
  position: "fixed",
  bottom: "1rem",
  right: "1rem",
  zIndex: 50,
  transition: "transform 0.3s ease-in-out",
};

export const cardStyle = {
  display: "flex",
  flexDirection: "column",
  height: "31.25rem",
  width: "22rem",
  border: "1px solid rgba(0, 0, 0, 0.1)",
  boxShadow:
    "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
};

export const cardHeaderStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid #e5e7eb",
  padding: "0.5rem",
};

export const cardTitleStyle = {
  marginBottom: 0,
  display: "flex",
  alignItems: "center",
  fontSize: "1.125rem",
  fontWeight: 500,
};

export const cardContentStyle = {
  flex: 1,
  padding: 0,
};
