import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useHandleNetworkError } from "../../../Utils/useHandleNetworkError";
import { createAxiosInstance } from "../../../api/axiosConfig";

export const useGetChat = (assignToStaffIdValue) => {
  const handleNetworkError = useHandleNetworkError((error) =>
    toast.error(`Error fetching chats: ${error.message}`)
  );

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  return useQuery({
    queryKey: ["chat", tenantVal, assignToStaffIdValue],
    queryFn: async () => {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}/chat/getbyassigntostaffid/${assignToStaffIdValue}`
      );

      return response.data;
    },
    refetchInterval: 30000,
    enabled: !!tenantVal && !!assignToStaffIdValue,
    onError: handleNetworkError,
  });
};
