import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import s from "./HorizontalCalendar.module.css";
import { Navigation } from "swiper/modules";
import NavLeft from "../../../../../assets/images/nav-arrow-left.svg";
import NavRight from "../../../../../assets/images/nav-arrow-right.svg";

const HorizontalCalendar = ({
  selectedDate,
  selectedMonth,
  selectedYear,
  setSelectedDate,
}) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [data, setData] = useState([]);

  const swiperRef = useRef(null);

  const generateDaysArray = (year, month) => {
    const daysInMonth = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
    return Array.from({ length: daysInMonth }, (_, index) => ({
      id: index + 1,
      week: moment(`${year}-${month}-${index + 1}`, "YYYY-MM-D").format("ddd"),
      num: index + 1,
    }));
  };

  useEffect(() => {
    const daysArray = generateDaysArray(selectedYear, selectedMonth);
    setData(daysArray);

    const currentDate = moment();
    const currentDay = currentDate.date();
    const currentMonth = currentDate.month() + 1;

    if (currentMonth === selectedMonth) {
      const currentDayIndex = daysArray.findIndex(
        (day) => day.num === currentDay
      );

      if (currentDayIndex !== -1 && swiperRef.current) {
        swiperRef.current.swiper.slideTo(currentDayIndex);
      }
    } else {
      if (swiperRef.current) {
        swiperRef.current.swiper.slideTo(0);
      }
    }
  }, [selectedMonth, selectedYear]);

  return (
    <div className={s.container}>
      <img
        src={NavLeft}
        alt="navigation left"
        ref={prevRef}
        className={s.navArrowLeft}
      />
      <Swiper
        className={s.swiperContainer}
        ref={swiperRef}
        clickable
        allowTouchMove
        slidesPerView={"auto"}
        spaceBetween={15}
        modules={[Navigation]}
        navigation={{
          nextEl: nextRef.current,
          prevEl: prevRef.current,
        }}
      >
        {data.map((day) => {
          const isPast = moment(
            `${selectedYear}-${selectedMonth}-${day.num}`,
            "YYYY-MM-DD"
          ).isBefore(moment(), "day");

          return (
            <SwiperSlide
              className={`${s.swiperSlide} ${
                selectedDate === day.num
                  ? s.selectedSlide
                  : isPast
                  ? s.pastDate
                  : ""
              }`}
              key={day.id}
              onClick={() => setSelectedDate(day.num)}
            >
              <span>{day.week}</span>
              <span>
                {day.num}{" "}
                {moment()
                  .month(selectedMonth - 1)
                  .format("MMM")}
              </span>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <img
        src={NavRight}
        alt="navigation right"
        ref={nextRef}
        className={s.navArrowRight}
      />
    </div>
  );
};

export default HorizontalCalendar;
