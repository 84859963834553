import * as React from "react";
import * as AvatarPrimitive from "@radix-ui/react-avatar";

const Avatar = React.forwardRef(({ className, style, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    style={{
      position: "relative",
      display: "flex",
      height: "3rem", // 12 * 0.25rem
      width: "3rem", // 12 * 0.25rem
      flexShrink: 0,
      overflow: "hidden",
      borderRadius: "9999px", // full
      backgroundColor: "white",
      padding: "2px",
      ...style,
    }}
    className={className}
    {...props}
  />
));
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef(({ className, style, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    style={{
      aspectRatio: "1 / 1",
      height: "100%",
      width: "100%",
      borderRadius: "9999px", // full
      ...style,
    }}
    className={className}
    {...props}
  />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef(
  ({ className, style, ...props }, ref) => (
    <AvatarPrimitive.Fallback
      ref={ref}
      style={{
        display: "flex",
        aspectRatio: "1 / 1",
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "9999px", // full
        backgroundColor: "#f3f4f6", // muted
        ...style,
      }}
      className={className}
      {...props}
    />
  )
);
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

export { Avatar, AvatarImage, AvatarFallback };
