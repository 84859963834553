import { PlusIcon } from "lucide-react";
import styles from "./Prescriptions.module.css";
import { Table } from "./Table/Table";
import { usePatientPrescriptions } from "../../../../../../hooks/usePatientPrescriptions";
import { AddPrescriptionModal } from "../../../../../../components/AddPrescriptionModal/AddPrescriptionModal";

export const Prescriptions = ({
  withoutBorder,
  withoutAddPrescription,
  withoutEditPrescription,
  patientId,
}) => {
  const { data: prescriptions, isLoading } = usePatientPrescriptions(patientId);

  return (
    <div
      className={withoutBorder ? styles.wrapperWithoutBorder : styles.wrapper}
    >
      <div className={styles.header}>
        <h2 className={styles.title}>Prescriptions</h2>
        {!withoutAddPrescription && (
          <AddPrescriptionModal
            buttonClassName={styles.addPrescriptionButton}
            buttonIcon={<PlusIcon width={18} height={18} />}
          />
        )}
      </div>
      <Table
        items={prescriptions ?? []}
        tableName="prescriptionsTable"
        isLoading={isLoading}
        withoutEditPrescription={withoutEditPrescription}
      />
    </div>
  );
};
