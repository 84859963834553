import { useState } from "react";

export const views = {
  noChronicDiseases: "noChronicDiseases",
  general: "general",
};

export const useView = (cdm) => {
  const [view, setView] = useState(
    cdm?.length <= 0
      ? {
          name: views.noChronicDiseases,
          pregnancyId: null,
        }
      : {
          name: views.general,
          pregnancyId: cdm?.at(0)?.id,
        }
  );

  return [view, setView];
};
