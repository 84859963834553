"use client";

import * as React from "react";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";

const ScrollArea = React.forwardRef(
  ({ className, children, style, ...props }, ref) => (
    <ScrollAreaPrimitive.Root
      ref={ref}
      style={{
        position: "relative",
        overflow: "hidden",
        ...style,
      }}
      className={className}
      {...props}
    >
      <ScrollAreaPrimitive.Viewport
        style={{
          height: "100%",
          width: "100%",
          borderRadius: "inherit",
        }}
      >
        {children}
      </ScrollAreaPrimitive.Viewport>
      <ScrollBar />
      <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
  )
);
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollBar = React.forwardRef(
  ({ className, orientation = "vertical", style, ...props }, ref) => (
    <ScrollAreaPrimitive.ScrollAreaScrollbar
      ref={ref}
      orientation={orientation}
      style={{
        display: "flex",
        touchAction: "none",
        userSelect: "none",
        transition: "background-color 0.2s",
        ...style,
        ...(orientation === "vertical"
          ? {
              height: "100%",
              width: "0.5rem", // 2 * 0.25rem
              borderLeft: "1px solid transparent",
              padding: "1px",
            }
          : {
              height: "0.625rem", // 2.5 * 0.25rem
              flexDirection: "column",
              borderTop: "1px solid transparent",
              padding: "1px",
            }),
      }}
      className={className}
      {...props}
    >
      <ScrollAreaPrimitive.ScrollAreaThumb
        style={{
          position: "relative",
          flex: 1,
          borderRadius: "9999px", // full
          backgroundColor: "#e5e7eb", // gray-200
        }}
      />
    </ScrollAreaPrimitive.ScrollAreaScrollbar>
  )
);
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export { ScrollArea, ScrollBar };
