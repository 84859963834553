import dayjs from "dayjs";
import React from "react";
import { z } from "zod";
import { useForm, FormProvider } from "react-hook-form";
import { Toaster } from "react-hot-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-calendar/dist/Calendar.css";

import style from "./AddMedicalHistoryForm.module.css";
import { FormField } from "../../../../../../form/FormField/FormField";
import { ICDCodeSelect } from "./components/ICDCodeSelect";
import { useAddMedicalHistory } from "../../../../../../../hooks/useAddMedicalHistory";

const formSchema = z.object({
  code: z.string().optional(),
  medicalDetails: z.string().optional(),
  date: z.string().min(1, {
    message: "Enter the date of the consultation.",
  }),
});

export const AddMedicalHistoryForm = ({ closeModal }) => {
  const today = dayjs(new Date().setMinutes(0));

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      type: "",
      date: dayjs().format("DD.MM.YYYY, HH:mm"),
    },
  });

  const { mutate: addMedicalHistory, isLoading } = useAddMedicalHistory();

  const handleFormSubmit = async (values) => {
    console.log(values);
    addMedicalHistory(
      {
        diseaseDate: moment(values.date, "DD.MM.YYYY, HH:mm").format(
          "YYYY-MM-DD"
        ),
        diseaseCode: values.code,
        medicalDetails: values.medicalDetails,
      },
      {
        onSuccess: () => {
          closeModal();
        },
      }
    );
  };

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <FormProvider {...form}>
        <form
          className={style.consultationForm}
          onSubmit={form.handleSubmit(handleFormSubmit)}
        >
          <div className={style.comboboxContainer}>
            <FormField name="dateGiven">
              <div className={style.datePickerWrapper}>
                <label className={style.formLabel}>Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD-MM-YYYY"
                    name="dateGiven"
                    defaultValue={today}
                    sx={{
                      width: "100%",
                      height: "36px",
                    }}
                    onChange={(newValue) => {
                      form.setValue(
                        "dateGiven",
                        moment(new Date(dayjs(newValue))).format("DD-MM-YYYY")
                      );
                    }}
                  />
                </LocalizationProvider>
              </div>
            </FormField>
            <ICDCodeSelect form={form}/>
            <FormField name="medicalDetails">
              <div className={style.datePickerWrapper}>
                <label className={style.formLabel}>Medical condition</label>
                <input
                    className="form-control"
                    {...form.register(`medicalDetails`)}
                />
              </div>
            </FormField>
          </div>
          <div className={style.buttonRow}>
            <button
              className={style.cancelButton}
              onClick={closeModal}
              type="button"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={style.submitButton}
              disabled={isLoading}
            >
              Add history
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
