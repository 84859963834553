import style from "./NoCdmSection.module.css";
import noCdmIcon from "../../../../../assets/images/cdm/empty-cdm.svg";
import CustomButton from "../../../../../components/helpers/CustomButton/CustomButton";

export const NoCdmSection = ({ onAddCdmButtonClick }) => {
  return (
    <section className={style.emptyState}>
      <img className={style.element} alt="Element" src={noCdmIcon} />
      <div className={style.text}>
        <div className={style.div}>
          <div className={style.textWrapper}>No Chronic Disease Recorded</div>
          <p className={style.onceThePatient}>
            There is currently no recorded chronic disease for this patient.
          </p>
        </div>
        <CustomButton
          buttonClassName={style.addPregnancyButton}
          titleClassName={style.addPregnancyButtonTitle}
          buttonHandle={onAddCdmButtonClick}
          title={"Start consultation"}
          icon={false}
        />
      </div>
    </section>
  );
};
