import { z } from "zod";
import { useStaffMembers } from "../../../hooks/useStaffMembers";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormField } from "../../form/FormField/FormField";
import style from "../chat-widget.module.css";

const formSchema = z.object({
  staffMember: z.string().min(1, { message: "Please select assigned to." }),
});

export const AddNewChat = ({ chats, addNewChat }) => {
  const { data: staffMembers } = useStaffMembers();
  const staffThatHasNoActiveChat = staffMembers?.filter(
    (staff) => !chats.some((chat) => chat.staffId === staff.id)
  );

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      staffMemeber: "",
    },
  });
  const staffMember = form.watch("staffMember");

  const handleFormSubmit = async (values) => {
    const selectedStaffMember = staffThatHasNoActiveChat?.find(
      (staff) => staff.id === Number(values.staffMember)
    );

    addNewChat(selectedStaffMember);
    form.reset();
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleFormSubmit)}
        className={style.addChatForm}
      >
        <FormField name="staffMember">
          <select
            as="select"
            className="form-select form-control custom-select"
            {...form.register("staffMember")}
          >
            <option value="">Select</option>
            {(staffThatHasNoActiveChat || [])?.map((staff) => (
              <option value={staff.id} key={staff.id}>
                {staff.firstName} {staff.lastName}
              </option>
            ))}
          </select>
        </FormField>
        <button
          className={style.sendButton}
          disabled={!staffMember}
          type="submit"
        >
          Add new chat
        </button>
      </form>
    </FormProvider>
  );
};
