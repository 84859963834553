import * as React from "react";

const Card = React.forwardRef(({ className, style, ...props }, ref) => (
  <div
    ref={ref}
    style={{
      borderRadius: "0.75rem", // rounded-xl
      border: "1px solid", // border
      backgroundColor: "#ffffff", // Replacing var(--bg-card) with white
      color: "#111827",
      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06)",
      ...style,
    }}
    className={className}
    {...props}
  />
));
Card.displayName = "Card";

const CardHeader = React.forwardRef(({ className, style, ...props }, ref) => (
  <div
    ref={ref}
    style={{
      display: "flex",
      flexDirection: "column",
      gap: "0.375rem",
      padding: "1.5rem",
      ...style,
    }}
    className={className}
    {...props}
  />
));
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef(({ className, style, ...props }, ref) => (
  <div
    ref={ref}
    style={{
      fontWeight: "600",
      lineHeight: "1",
      letterSpacing: "-0.015em",
      ...style,
    }}
    className={className}
    {...props}
  />
));
CardTitle.displayName = "CardTitle";

const CardDescription = React.forwardRef(
  ({ className, style, ...props }, ref) => (
    <div
      ref={ref}
      style={{
        fontSize: "0.875rem",
        color: "#6b7280",
        ...style,
      }}
      className={className}
      {...props}
    />
  )
);
CardDescription.displayName = "CardDescription";

const CardContent = React.forwardRef(({ className, style, ...props }, ref) => (
  <div
    ref={ref}
    style={{
      padding: "1.5rem",
      paddingTop: "0",
      ...style,
    }}
    className={className}
    {...props}
  />
));
CardContent.displayName = "CardContent";

const CardFooter = React.forwardRef(({ className, style, ...props }, ref) => (
  <div
    ref={ref}
    style={{
      display: "flex",
      alignItems: "center",
      padding: "1.5rem",
      paddingTop: "0",
      ...style,
    }}
    className={className}
    {...props}
  />
));
CardFooter.displayName = "CardFooter";

export {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
};
